<template>
	<div>
		<b-row>
		    <b-col cols="12">
		      <div style="border: solid 1px #20a2ce;">
		        <i class="fa fa-eraser intLink" title="Clean" @click="f_clearHtmlTextDiv()"></i>
		        <i class="fa fa-print intLink" title="Print" @click="f_printDoc();"></i>
		        <i class="fa fa-undo intLink" title="Undo" @click="f_formatDoc('undo')"></i>
		        <i class="fa fa-repeat intLink" title="Redo" @click="f_formatDoc('redo')"></i>
		        <img class="intLink" title="Remove formatting" @click="f_formatDoc('removeFormat')" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAd0SU1FB9oECQMCKPI8CIIAAAAIdEVYdENvbW1lbnQA9syWvwAAAuhJREFUOMtjYBgFxAB501ZWBvVaL2nHnlmk6mXCJbF69zU+Hz/9fB5O1lx+bg45qhl8/fYr5it3XrP/YWTUvvvk3VeqGXz70TvbJy8+Wv39+2/Hz19/mGwjZzuTYjALuoBv9jImaXHeyD3H7kU8fPj2ICML8z92dlbtMzdeiG3fco7J08foH1kurkm3E9iw54YvKwuTuom+LPt/BgbWf3//sf37/1/c02cCG1lB8f//f95DZx74MTMzshhoSm6szrQ/a6Ir/Z2RkfEjBxuLYFpDiDi6Af///2ckaHBp7+7wmavP5n76+P2ClrLIYl8H9W36auJCbCxM4szMTJac7Kza////R3H1w2cfWAgafPbqs5g7D95++/P1B4+ECK8tAwMDw/1H7159+/7r7ZcvPz4fOHbzEwMDwx8GBgaGnNatfHZx8zqrJ+4VJBh5CQEGOySEua/v3n7hXmqI8WUGBgYGL3vVG7fuPK3i5GD9/fja7ZsMDAzMG/Ze52mZeSj4yu1XEq/ff7W5dvfVAS1lsXc4Db7z8C3r8p7Qjf///2dnZGxlqJuyr3rPqQd/Hhyu7oSpYWScylDQsd3kzvnH738wMDzj5GBN1VIWW4c3KDon7VOvm7S3paB9u5qsU5/x5KUnlY+eexQbkLNsErK61+++VnAJcfkyMTIwffj0QwZbJDKjcETs1Y8evyd48toz8y/ffzv//vPP4veffxpX77z6l5JewHPu8MqTDAwMDLzyrjb/mZm0JcT5Lj+89+Ybm6zz95oMh7s4XbygN3Sluq4Mj5K8iKMgP4f0////fv77//8nLy+7MCcXmyYDAwODS9jM9tcvPypd35pne3ljdjvj26+H2dhYpuENikgfvQeXNmSl3tqepxXsqhXPyc666s+fv1fMdKR3TK72zpix8nTc7bdfhfkEeVbC9KhbK/9iYWHiErbu6MWbY/7//8/4//9/pgOnH6jGVazvFDRtq2VgiBIZrUTIBgCk+ivHvuEKwAAAAABJRU5ErkJggg==">
		        <i class="fa fa-bold intLink" title="Bold" @click="f_formatDoc('bold')"></i>
		        <i class="fa fa-italic intLink" title="Italic" @click="f_formatDoc('italic')"></i>
		        <i class="fa fa-underline intLink" title="Underline" @click="f_formatDoc('underline')"></i>
		        <i class="fa fa-align-left intLink " title="Left Align" @click="f_formatDoc('justifyleft')" ></i>
		        <i class="fa fa-align-center intLink " title="Center align" @click="f_formatDoc('justifycenter')"></i>
		        <i class="fa fa-align-right intLink" title="Right align" @click="f_formatDoc('justifyright')"></i>
		        <i class="fa fa-list-ol intLink" title="Numbered list" @click="f_formatDoc('insertorderedlist')"></i>
		        <i class="fa fa-list-ul intLink" title="Dotted list" @click="f_formatDoc('insertunorderedlist')"></i>
		        <!-- <i class="fa fa-quote-left intLink" title="Quote" @click="f_formatDoc('formatblock','blockquote')"></i> -->
		        <!-- <img class="intLink" title="Delete indentation" @click="f_formatDoc('outdent');" src="data:image/gif;base64,R0lGODlhFgAWAMIHAAAAADljwliE35GjuaezxtDV3NHa7P///yH5BAEAAAcALAAAAAAWABYAAAM2eLrc/jDKCQG9F2i7u8agQgyK1z2EIBil+TWqEMxhMczsYVJ3e4ahk+sFnAgtxSQDqWw6n5cEADs=" /> -->
		        <!-- <img class="intLink" title="Add indentation" @click="f_formatDoc('indent');" src="data:image/gif;base64,R0lGODlhFgAWAOMIAAAAADljwl9vj1iE35GjuaezxtDV3NHa7P///////////////////////////////yH5BAEAAAgALAAAAAAWABYAAAQ7EMlJq704650B/x8gemMpgugwHJNZXodKsO5oqUOgo5KhBwWESyMQsCRDHu9VOyk5TM9zSpFSr9gsJwIAOw==" /> -->
		        <!-- <img class="intLink" title="Hyperlink" @click="var sLnk=prompt('Write the URL here','http:\/\/');if(sLnk&&sLnk!=''&&sLnk!='http://'){f_formatDoc('createlink',sLnk)}" src="data:image/gif;base64,R0lGODlhFgAWAOMKAB1ChDRLY19vj3mOrpGjuaezxrCztb/I19Ha7Pv8/f///////////////////////yH5BAEKAA8ALAAAAAAWABYAAARY8MlJq7046827/2BYIQVhHg9pEgVGIklyDEUBy/RlE4FQF4dCj2AQXAiJQDCWQCAEBwIioEMQBgSAFhDAGghGi9XgHAhMNoSZgJkJei33UESv2+/4vD4TAQA7" /> -->
		        <i class="fa fa-scissors intLink" title="Cut" @click="f_formatDoc('cut');"></i>
		        <i class="fa fa-files-o intLink" title="Copy" @click="f_formatDoc('copy');"></i>
		        <i class="fa fa-clipboard intLink" title="Paste" @click="f_formatDoc('paste');"></i>
		        <i class="fa fa-file-code-o intLink" title="HTML yeniden düzenle" @click="f_htmlToOnlyDivMode();"></i>
		        <select v-model="d_textOptions.formatblock" v-on:change="f_formatDocSelect('formatblock')" style="width: 75px; font-size: 16px;">
		          <option value="">format</option>
		          <option value="h1">{{ StoreLangTranslation.data['title_1'][StoreLang] }} &lt;h1&gt;</option>
		          <option value="h2">{{ StoreLangTranslation.data['title_2'][StoreLang] }} &lt;h2&gt;</option>
		          <option value="h3">{{ StoreLangTranslation.data['title_3'][StoreLang] }} &lt;h3&gt;</option>
		          <option value="h4">{{ StoreLangTranslation.data['title_4'][StoreLang] }} &lt;h4&gt;</option>
		          <option value="h5">{{ StoreLangTranslation.data['title_5'][StoreLang] }} &lt;h5&gt;</option>
		          <option value="h6">{{ StoreLangTranslation.data['subtitle'][StoreLang] }} &lt;h6&gt;</option>
		          <!-- <option value="p">Paragraph &lt;p&gt;</option> -->
		          <!-- <option value="pre">Preformatted &lt;pre&gt;</option> -->
		        </select>
		        <select v-model="d_textOptions.fontname" v-on:change="f_formatDocSelect('fontname')" style="width: 75px; font-size: 16px;">
		          <option value="">font</option>
		          <option value="Font">Font</option>
		          <option value="Arial">Arial</option>
		          <option value="Arial Black">Arial Black</option>
		          <option value="Courier New">Courier New</option>
		          <option value="Times New Roman">Times New Roman</option>
		          <option value="Tahoma">Tahoma</option>
		        </select>
		        <select v-model="d_textOptions.fontsize" v-on:change="f_formatDocSelect('fontsize')" style="width: 75px; font-size: 16px;">
		          <option value="">{{ StoreLangTranslation.data['size'][StoreLang] }}</option>
		          <option value="1">{{ StoreLangTranslation.data['very_small'][StoreLang] }}</option>
		          <option value="2">{{ StoreLangTranslation.data['small'][StoreLang] }}</option>
		          <option value="3">{{ StoreLangTranslation.data['normal'][StoreLang] }}</option>
		          <option value="4">{{ StoreLangTranslation.data['medium_large'][StoreLang] }}</option>
		          <option value="5">{{ StoreLangTranslation.data['large'][StoreLang] }}</option>
		          <option value="6">{{ StoreLangTranslation.data['very_large'][StoreLang] }}</option>
		          <option value="7">{{ StoreLangTranslation.data['maximum_size'][StoreLang] }}</option>
		        </select>
		        <select v-model="d_textOptions.forecolor" v-on:change="f_formatDocSelect('forecolor')" style="width: 75px; font-size: 16px;">
		          <option value="">{{ StoreLangTranslation.data['color'][StoreLang] }}</option>
		          <option value="white">{{ StoreLangTranslation.data['white'][StoreLang] }}</option>
		          <option value="red">{{ StoreLangTranslation.data['red'][StoreLang] }}</option>
		          <option value="blue">{{ StoreLangTranslation.data['blue'][StoreLang] }}</option>
		          <option value="green">{{ StoreLangTranslation.data['green'][StoreLang] }}</option>
		          <option value="black">{{ StoreLangTranslation.data['black'][StoreLang] }}</option>
		        </select>
		        <select v-model="d_textOptions.backcolor" v-on:change="f_formatDocSelect('backcolor')" style="min-width: 75px; font-size: 16px;">
		          <option value="">{{ StoreLangTranslation.data['background'][StoreLang] }}</option>
		          <option value="white">{{ StoreLangTranslation.data['white'][StoreLang] }}</option>
		          <option value="red">{{ StoreLangTranslation.data['red'][StoreLang] }}</option>
		          <option value="blue">{{ StoreLangTranslation.data['blue'][StoreLang] }}</option>
		          <option value="green">{{ StoreLangTranslation.data['green'][StoreLang] }}</option>
		          <option value="black">{{ StoreLangTranslation.data['black'][StoreLang] }}</option>
		        </select>
		      </div>
		    </b-col>
	  	</b-row>
		<div autocomplete="off" tabindex="-1" :id="p_htmlElementId" contenteditable="true" :style="f_calculateHtmlEditorStyle()">
			<div v-html="p_html_text">...</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	export default {
	  name: 'HtmlEditor',
	  computed: mapGetters({
	    StoreLang: 'StoreLang',
	    StoreLangTranslation: 'StoreLangTranslation',
	  }),
	  props: {
	  	p_html_text:{
	  		type: String,
     	 	required: true
	  	},
	  	p_htmlElementId:{
  			type: String,
     		required: false,
     		default: "default"
	  	}
	  },
	  data () {
	  	return {
		    d_textOptions: {
		      'formatblock': '',
		      'fontname': 'Tahoma',
		      'fontsize': '',
		      'forecolor': '',
		      'backcolor': ''
		    },
			d_htmlEditorSettings: {
			  'height': 300,
			  'backgroundColor': 'white',
			  'fontSize': 14,
			  'fontFamily': 'Tahoma'
		 	}, 
	  	}
	  },	
	  created: function () {

	  },
	  mounted: function () {
	    setTimeout(function () {
		  // this.setDocMode();
		  let div_el = document.getElementById(this.p_htmlElementId);
		  // console.log("div_el : ", div_el);
		  // console.log("this.p_html_text ", this.p_html_text);
		  let div_html_text = '';
		  if (this.p_html_text) {
		  	// div_html_text = this.p_html_text;
		  	if(!this.p_html_text.startsWith('<span') && !this.p_html_text.startsWith('<div><span')){
		    	div_html_text = '<span style="font-weight: normal;">' + this.p_html_text + '</span>';
		  	}
		  	else{
		  		div_html_text = this.p_html_text;
		  	}
		  }
		  if (div_el) {
		    this.f_initHtmlContextTemplate(this.p_htmlElementId, div_html_text, 'equal', true);
		  }
		}.bind(this), 500)
	  },
	  methods: {
	  	f_calculateHtmlEditorStyle: function () {
	      let style = "overflow-x: hidden; overflow-y: auto; color: black; cursor:text; border: solid 1px #20a2ce; padding: 3px; width: 100%;";
	      style += 'min-height:' + this.d_htmlEditorSettings.height.toString() + 'px;';
	      style += 'background-color:' + this.d_htmlEditorSettings.backgroundColor + ';';
	      style += 'font-size:' + this.d_htmlEditorSettings.fontSize + 'px;';
	      style += 'font-family:' + this.d_htmlEditorSettings.fontFamily + ';';
	      return style;
	    },
	    f_initHtmlContextTemplate: function (div_element_id, html_txt = '', type = 'equal', create_paste_event = false, wait = true) {
	      if (wait) {
	        setTimeout(function () {
	          let div_el = document.getElementById(div_element_id);
	          if (div_el) {
	            document.execCommand("defaultParagraphSeparator", false, "div");
	            if (create_paste_event) {
	              this.f_createPasteEvent(div_el);
	            }
	            if (type === 'equal') {
	              div_el.innerHTML = html_txt;
	            } else if (type === 'plus_add') {
	              div_el.innerHTML += html_txt;
	            }
	            // div_el.focus();
	            this.$forceUpdate();
	          }
	        }.bind(this), 50)
	      } else {
	        let div_el = document.getElementById(div_element_id);
	        if (div_el) {
	          document.execCommand("defaultParagraphSeparator", false, "div");
	          if (create_paste_event) {
	            this.f_createPasteEvent(div_el);
	          }
	          if (type === 'equal') {
	            div_el.innerHTML = html_txt;
	          } else if (type === 'plus_add') {
	            div_el.innerHTML += html_txt;
	          }
	          div_el.focus();
	          this.$forceUpdate();
	        }
	      }
	    },
	    f_createPasteEvent: function (div_el) {
	      div_el.addEventListener('paste', (event) => {
	        let before_paste = div_el.innerHTML;
	        // console.log('before_paste : ', before_paste);
	        let paste = (event.clipboardData || window.clipboardData).getData('text');
	        // console.log('paste :', paste);
	        let splitted_paste = paste.split('\r\n');
	        // console.log('splitted_paste :', splitted_paste);
	        let added_text = '';
	        const selection = window.getSelection();
	        // console.log(selection);
	        selection.deleteFromDocument();
	        if (!selection.rangeCount) return false;
	        if (splitted_paste.length > 1) {
	          let i = 0;
	          for (let i = splitted_paste.length - 1; i >= 0; i--) {
	            if (splitted_paste[i]) {
	              // added_text += '<div>' + splitted_paste[i] + '</div>';
	              // this.f_insertTextAtCursor(paste);
	              let div_sentence = document.createElement("div");
	              let text_node = document.createTextNode(splitted_paste[i]);
	              div_sentence.appendChild(text_node);
	              selection.getRangeAt(0).insertNode(div_sentence);
	              // added_text += splitted_paste[i]
	            }
	          }
	        } else if (splitted_paste.length === 1) {
	          let text_node = document.createTextNode(splitted_paste[0]);
	          selection.getRangeAt(0).insertNode(text_node);
	        }
	        event.preventDefault();
	        // paste = added_text;
	        // this.f_insertTextAtCursor(paste);
	        /*
	          paste = paste.replace(/<span[^>]*>/g, " ");
	          paste = paste.replace(/<\/span>/g, " ");
	          paste = paste.replace(/<u[^>]*>/g, " ");
	          paste = paste.replace(/<\/u>/g, " ");
	          paste = paste.replace(/<p(.*?)>/g, "<div>");
	          paste = paste.replace(/<\/p>/g, "<\/div>");
	          paste = paste.replace(/<o:p>/g, " ");
	          paste = paste.replace(/<\/o:p>/g, " ");
	          paste = paste.replace(/class=\"(.*?)\"/g, " ");
	          paste = paste.replace(/style=\"(.*?)\"/g, " ");
	          paste = paste.replace(/\r/g, " ");
	          paste = paste.replace(/\n/g, " ");
	          paste = paste.replace(/&nbsp;/g, " ");
	          paste = paste.replace(/[\s]+/g, " ");
	          paste = paste.replace(/\s\./g, ".");
	        */
	        // console.log('paste : ', paste);
	        /*
	        const selection = window.getSelection();
	        if (!selection.rangeCount) return false;
	        selection.deleteFromDocument();
	        // this.f_insertTextAtCursor(paste);
	        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
	        event.preventDefault();
	        */
	        /*
	          setTimeout(function () {
	            div_el.innerHTML = before_paste + added_text;
	          }.bind(this), 100)
	        */
	        // div_el.focus();
	      });
	    },
	    f_clearHtmlTextDiv: function () {
	      if (confirm('Html içeriğini temizlemek istediğinize emin misiniz ?')) {
	        this.f_initHtmlContextTemplate(this.p_htmlElementId, '', 'equal', true);
	      }
	    },
	    f_printDoc: function () {
	      let oDoc = document.getElementById(this.p_htmlElementId);
	      var oPrntWin = window.open("", "_blank", "width=450,height=470,left=400,top=100,menubar=yes,toolbar=no,location=no,scrollbars=yes");
	      oPrntWin.document.open();
	      oPrntWin.document.write("<!doctype html><html><head><title>Print<\/title><\/head><body onload=\"print();\">" + oDoc.innerHTML + "<\/body><\/html>");
	      oPrntWin.document.close();
	    },
	    f_formatDoc: function (sCmd, sValue=null) {
	    	// console.log(sCmd)
	    	document.execCommand(sCmd);
	    	// document.execCommand(sCmd, false, sValue);
			// let oDoc = document.getElementById(this.p_htmlElementId);
			// oDoc.focus();
			// console.log(oDoc.innerHTML);
	    },
	    f_htmlToOnlyDivMode: function () {
	      let div_el = document.getElementById(this.p_htmlElementId);
	      let div_el_text_data = div_el.innerText;
	      // console.log('div_el_text_data :', div_el_text_data);
	      let sentence_splitted = div_el_text_data.split('\n');
	      // console.log('sentence_splitted : ', sentence_splitted);
	      let new_html_text = '';
	      let free_rows_count = 0;
	      for (let i in sentence_splitted) {
	        if (sentence_splitted[i]) {
	          free_rows_count = 0;
	          // new_html_text += '<div>' + sentence_splitted[i] + '</div>';
	          new_html_text += '<span style="font-weight: normal;"><div>' + sentence_splitted[i] + '</div><span>';
	        } else {
	          free_rows_count += 1;
	          if (free_rows_count === 1) {
	            new_html_text += '<div></br></div>';
	          }
	        }
	      }
	      div_el.innerHTML = new_html_text;
	      this.$forceUpdate();
	    },
	    f_formatDocSelect: function (sCmd) {
	      let sValue = this.d_textOptions[sCmd];
	      document.execCommand(sCmd, false, sValue);
	      let oDoc = document.getElementById(this.p_htmlElementId);
	      oDoc.focus();
	    },
	  }
	}
</script>
<style>
	.intLink{
		font-size: 16px;
		margin: 0px 3px;
		cursor: pointer;
	}
</style>