<template>
<div style="min-height: 750px; font-size: 16px;">
    <!-- {{ d_statsList }} -->
    <b-tabs>
      <b-tab v-if="['wanalyzer_create_query'].indexOf(p_selectedProjectType) !== -1" :active="d_selectedTab === 'general_settings'" @click="d_selectedTab = 'general_settings'">
        <template slot="title">
          {{ StoreLangTranslation.data['general_settings'][StoreLang] }}
        </template>
        <template v-if="d_selectedTab === 'general_settings'">
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="10">
              {{ StoreLangTranslation.data['general_settings'][StoreLang] }}
            </b-col>
            <b-col sm="12" lg="2">
              <template v-if="d_projectSaveLoading">
                  <b-spinner variant="primary" label="Spinning"></b-spinner>
              </template>
              <template v-else>
                <b-button variant="outline-primary" size="xl" @click="f_wanalyzerProjectSave()" style="width: 100%;" :disabled="f_projectSaveEligibleCheck()">
                  <i class="fa fa-send-o"></i> {{ StoreLangTranslation.data['save'][StoreLang] }}
                </b-button>
              </template>
            </b-col>
          </b-row>
          <hr>

          <b-row style="margin: 3px;">
            <b-col sm="12" lg="4">
              <strong>
                {{ StoreLangTranslation.data['project_name'][StoreLang] }}
              </strong>
            </b-col>
            <b-col sm="12" lg="8">
              <b-form-group validated label-for="">
                <b-textarea v-model="p_projectPermission.wanalyzer_project_name" rows="3" required></b-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>

          <template v-if="['wanalyzer_create_query'].indexOf(p_selectedProjectType) !== -1">
            <b-row style="margin: 3px;">
              <b-col sm="12" lg="4">
                <strong>
                  {{ StoreLangTranslation.data['case_limit_count'][StoreLang] }}
                </strong>
              </b-col>
              <b-col sm="12" lg="6">
                <template v-if="p_projectPermission.case_limit_count">
                  <b-form-group validated label-for="">
                    <b-form-input type="number" v-model="p_projectPermission.case_limit_count_set" required :max="parseInt(p_projectPermission.case_limit_count)"></b-form-input>
                  </b-form-group>
                </template>
                <template v-else>
                  <b-form-input type="number" v-model="p_projectPermission.case_limit_count_set"></b-form-input>
                </template>
              </b-col>
              <b-col sm="12" lg="2">
                <small v-if="p_projectPermission.case_limit_count" style="color: red;">(max: {{ p_projectPermission.case_limit_count }})</small>
              </b-col>
            </b-row>
            <hr>
          </template>

          <template v-if="['wanalyzer_create_query'].indexOf(p_selectedProjectType) !== -1">
            <b-row style="margin: 3px;">
              <b-col sm="12" lg="4">
                <strong>
                  {{ StoreLangTranslation.data['main_layer'][StoreLang] }}
                </strong>
              </b-col>
              <b-col sm="12" lg="8">
                <b-form-select v-model="p_projectPermission.main_layer">
                  <option v-for="(layer_item, layer_item_index) in p_projectPermission.layer_list" :value="layer_item.layer.value">
                    {{ layer_item.layer.name[StoreLang] }}
                  </option>
                </b-form-select>
              </b-col>
            </b-row>
            <hr>
          </template>

          <template v-if="['wanalyzer_create_query'].indexOf(p_selectedProjectType) !== -1">
            <b-row style="margin: 3px;">
              <b-col sm="12" lg="4">
                <strong>
                  {{ StoreLangTranslation.data['location'][StoreLang] }}
                </strong>
              </b-col>
              <b-col sm="12" lg="8">
                <template v-for="(location, location_index) in p_projectPermission.location_list">
                  <b-card-header :key="'header' + location_index" header-bg-variant="white" header-text-variant="dark" class="p-1" :title="location.name[StoreLang]">
                    <b-row>
                      <b-col sm="12" lg="12">
                        <h4 @click="f_selectLocation(location_index)" :style="location.selected ? 'cursor: pointer; color: rgb(2 123 255); font-weight: bold;' : 'cursor: pointer; '">
                          <i v-if="!location.selected" class="fa fa-minus-square-o"></i>
                          <i v-if="location.selected" class="fa fa-check-square"></i>
                          {{ location.name[StoreLang] }}
                        </h4>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <template v-if="location.selected">
                      <template v-for="(server, server_index) in location.server_list">
                        <div @click="f_selectServer(location_index, server_index)" :style="server.selected ? 'cursor: pointer; color: rgb(2 123 255); font-weight: bold; padding: 5px; background-color: #f8f8f8; margin: 3px;' : 'cursor: pointer; padding: 5px; margin: 3px;'">
                          <i v-if="!server.selected" class="fa fa-minus-square-o"></i>
                          <i v-if="server.selected" class="fa fa-check-square"></i>
                          {{ server.name[StoreLang] }}
                        </div>
                      </template>
                  </template>
                </template>
              </b-col>
            </b-row>
            <hr>
          </template>

          <template v-if="['wanalyzer_create_query'].indexOf(p_selectedProjectType) !== -1">
            <b-row style="margin: 3px;">
              <b-col sm="12" lg="10">
                {{ StoreLangTranslation.data['main_rule'][StoreLang] }}
              </b-col>
              <b-col sm="12" lg="2">
                <b-button variant="outline-primary" size="sm" @click="f_editMainRuleAlgorithm()">
                  <i class="fa fa-send-o"></i> {{ StoreLangTranslation.data['edit_main_rule_algorithm'][StoreLang] }}
                </b-button>
              </b-col>
            </b-row>
            <b-row v-if="!d_mainRuleShowModal" style="margin: 3px;">
              <b-col sm="12" lg="12">
                <b-row style="margin-top: 10px;">
                  <b-col sm="12" lg="12">
                    <template v-if="p_projectPermission.main_rule_algorithm_set.query_list && p_projectPermission.main_rule_algorithm_set.query_list.length > 0">
                      <rule-query :query_list="p_projectPermission.main_rule_algorithm_set.query_list" :compile_str="p_projectPermission.main_rule_algorithm_set.compile_str_array" :compile_str_date="p_projectPermission.main_rule_algorithm_set.compile_str_date" :mode="'view'" :compile_str_text="p_projectPermission.main_rule_algorithm_set.compile_str_text"></rule-query>
                    </template>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <hr>
          </template>
        </template>
      </b-tab>


      <b-tab v-if="['wanalyzer_create_query'].indexOf(p_selectedProjectType) !== -1" :active="d_selectedTab === 'column_list'" @click="d_selectedTab = 'column_list'">
        <template slot="title">
          {{ StoreLangTranslation.data['column_list'][StoreLang] }}
        </template>
        <template v-if="d_selectedTab === 'column_list'">
          <template v-if="d_columnListLoading">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </template>
          <template v-else>
            <wanalyzer-project-column :p_columnList="d_columnList" :p_projectPermission="p_projectPermission" :f_wanalyzerProjectColumnDataList="f_wanalyzerProjectColumnDataList" :p_fullScreen="p_fullScreen"></wanalyzer-project-column>
          </template>
        </template>
      </b-tab>



      <b-tab v-if="['wanalyzer_create_query'].indexOf(p_selectedProjectType) !== -1" :active="d_selectedTab === 'dataset'" @click="d_selectedTab = 'dataset'">
        <template slot="title">
          {{ StoreLangTranslation.data['dataset'][StoreLang] }}
        </template>
        <template v-if="d_selectedTab === 'dataset'">
          <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
            <b-row>
              <b-col sm="12" lg="4">
                <!-- <h5> {{ StoreLangTranslation.data['results'][StoreLang] }} </h5> -->
              </b-col>
              <b-col sm="12" lg="2">
                <template v-if="d_projectDataQueryLoading">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                    {{ StoreLangTranslation.data['continuing'][StoreLang] }}
                </template>
              </b-col>
              <b-col sm="12" lg="6" style="text-align: right;">
                <b-button variant="outline-primary" size="sm" @click="f_wanalyzerDataQuery('delete_all_and_recreate')" :title="StoreLangTranslation.data['delete_all_and_recreate'][StoreLang]" :disabled="d_columnList.length === 0 || d_projectDataQueryLoading">
                  <i class="fa fa-database"></i>
                  {{ StoreLangTranslation.data['delete_all_and_recreate'][StoreLang] }}
                  <small v-if="d_columnList.length === 0" style="color: red;">
                    ({{ StoreLangTranslation.data['first_create_column'][StoreLang] }})
                  </small>
                </b-button>
              </b-col>
            </b-row>
          </b-card-header>
          <template v-if="d_datasetViewLoading">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
          </template>
          <template v-else>
            <w-table2 style="transition: 2s; transform: none;" v-if="d_datasetWTable && d_datasetShow" :key="'dataset_view'" :p_tableDetails="d_datasetWTable"></w-table2>
          </template>
        </template>
      </b-tab>


      <b-tab v-if="['wanalyzer_create_query'].indexOf(p_selectedProjectType) !== -1" :active="d_selectedTab === 'data_count'" @click="d_selectedTab = 'data_count'">
        <template slot="title">
          {{ StoreLangTranslation.data['data_count'][StoreLang] }}
        </template>
        <template v-if="d_selectedTab === 'data_count'">
          <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
            <b-row>
              <b-col sm="12" lg="4">
              </b-col>
              <b-col sm="12" lg="2">
                <template v-if="d_projectDataQueryLoading">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                    {{ StoreLangTranslation.data['continuing'][StoreLang] }}
                </template>
              </b-col>
              <b-col sm="12" lg="6" style="text-align: right;">
                <b-button variant="outline-success" size="sm" @click="f_wanalyzerDataQuery('calculate_case_count')" :title="StoreLangTranslation.data['calculate_case_count'][StoreLang]" :disabled="d_projectDataQueryLoading">
                  <i class="fa fa-calculator"></i>
                  {{ StoreLangTranslation.data['calculate_case_count'][StoreLang] }}
                </b-button>                      
              </b-col>
            </b-row>
          </b-card-header>
          <template v-if="d_datasetViewLoading">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
          </template>
          <template v-else>
            <div style="min-height: 300px;">
              <b-card-header v-if="d_project.details.calculated_case_count !== undefined" header-bg-variant="white" class="p-1" header-text-variant="dark">
                <strong>
                  {{ StoreLangTranslation.data['total_case_count'][StoreLang] }} :
                  {{ d_project.details.calculated_case_count }} </strong>
                  /
                  {{ StoreLangTranslation.data['last_update'][StoreLang] }} :
                  {{ d_project.details['locations_count_update_date'] ? f_dateFormat(d_project.details['locations_count_update_date'], 'year-month-day hh:mm') : '' }}
              </b-card-header>
              <template v-if="d_project.details.locations_data_count && d_project.details.locations_data_count.location_order && d_project.details.locations_data_count.location_order.length > 0">
                <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
                  <strong> {{ StoreLangTranslation.data['location'][StoreLang] }} </strong>
                </b-card-header>
                <template v-for="(location_order, location_order_index) in d_project.details.locations_data_count.location_order">
                  <b-row style="margin: 3px;">
                    <b-col cols="4">
                      <strong>{{ d_project.details.locations_data_count.location_results[location_order].info.label }}</strong>
                    </b-col>
                    <b-col cols="1">
                        {{ d_project.details.locations_data_count.location_results[location_order].total_count }}
                    </b-col>
                    <b-col cols="7">
                        <b-progress :value="(d_project.details.locations_data_count.location_results[location_order].total_count / d_project.details.calculated_case_count) * 100" class="mb-3"></b-progress>
                    </b-col>
                  </b-row>
                  <template v-for="(server_order, server_order_index) in d_project.details.locations_data_count.location_results[location_order].server_order">
                    <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                      <b-col cols="4">
                        <span style="margin-left: 20px;">
                          {{ server_order_index + 1 }} )
                          {{ d_project.details.locations_data_count.location_results[location_order].server_results[server_order].info.label }}
                        </span>
                      </b-col>
                      <b-col cols="1">
                        <strong> {{ d_project.details.locations_data_count.location_results[location_order].server_results[server_order].total_count }} </strong>
                      </b-col>
                      <b-col cols="7">
                        <b-progress :value="(d_project.details.locations_data_count.location_results[location_order].server_results[server_order].total_count / d_project.details.calculated_case_count) * 100"> </b-progress>
                      </b-col>
                    </b-row>
                  </template>
                </template>
              </template>
            </div>
          </template>
        
        </template>
      </b-tab>


      <b-tab v-if="['wanalyzer_dataset_stats_query', 'wanalyzer_dataset_filter', 'wanalyzer_create_query'].indexOf(p_selectedProjectType) !== -1" :active="d_selectedTab === 'dataset_filter'" @click="d_selectedTab = 'dataset_filter'">
        <template slot="title">
          {{ StoreLangTranslation.data['dataset_filter'][StoreLang] }}
        </template>
        <template v-if="d_selectedTab === 'dataset_filter'">
          <b-row style="border-top: solid 1px lightblue;">
              <b-col sm="12" md="12" lg="4">
                <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
                  <b-row>
                    <b-col sm="12" lg="4">
                      <h5>
                        {{ StoreLangTranslation.data['variables'][StoreLang] }}
                      </h5>
                    </b-col>
                    <b-col sm="12" lg="4" style="text-align: right;"> </b-col>
                    <b-col sm="12" lg="4" style="text-align: right;">
                      <b-button variant="outline-primary" size="sm" @click="f_resetProjectData()" :title="StoreLangTranslation.data['reset'][StoreLang]">
                        <i class="fa fa-refresh"></i>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card-header>
                <template v-if="d_projectColumnListLoading">
                  <b-spinner variant="primary" label="Spinning"></b-spinner>
                </template>
                <template v-else>
                  <template v-for="(col, col_ind) in d_project.column_list">
                    <template v-if="col.param_type === 'select' || col.param_type === 'radiogroup'">

                      <b-row :style="col.use_as_stats === 'yes' ? 'padding: 3px; background-color: #e3f6ff; border-bottom: solid 1px rgb(227, 246, 255);' : 'padding: 3px; border-bottom: solid 1px rgb(227, 246, 255);'">

                        <b-col v-if=
                        "['wanalyzer_create_query'].indexOf(p_selectedProjectType) !== -1 || (['wanalyzer_dataset_stats_query', 'wanalyzer_dataset_filter'].indexOf(p_selectedProjectType) !== -1 && col.wai_output)" sm="12" lg="2" @click="f_selectColumnUseAsStats(col_ind)">
                          <i class="fa fa-check" :style="col.use_as_stats === 'yes' ? 'color: #009cff;' : 'color: grey;'"></i>
                        </b-col>

                        <b-col v-else sm="12" lg="2"> </b-col>

                        <b-col sm="12" lg="3">
                            <b-dropdown id="dropdown-grouped" variant="outline-white" no-caret style="width: 100%;">
                              <template #button-content>
                                <div style="font-size: 12px; white-space: pre-wrap;">
                                  {{ col.name }}
                                </div>
                              </template>
                              <!-- <b-row style="width: 100%;">
                                <b-col sm="12" lg="1"></b-col>
                                <b-col sm="12" lg="8">
                                  <b-form-input v-model="d_searchText" :placeholder="StoreLangTranslation.data['write_for_filtering'][StoreLang]"></b-form-input>
                                </b-col>
                                <b-col sm="12" lg="3">
                                  <i class="fa fa-trash" style="cursor: pointer;" @click="d_searchText = ''"></i>
                                </b-col>
                              </b-row> -->
                              <b-dropdown-item-button @click="f_addToColumnSelectedItems('dataset_filter', col_ind, '')">
                                <i class="fa fa-plus-square"></i> {{ StoreLangTranslation.data['all'][StoreLang] }}
                              </b-dropdown-item-button>
                              <div style="overflow-x: hidden; overflow-y: auto; height: 300px;">
                                <template v-for="(opt, opt_ind) in col.options">
                                  <b-dropdown-item-button @click="f_addToColumnSelectedItems('dataset_filter', col_ind, opt)">
                                    <i class="fa fa-plus-square"></i>  {{ opt.label }}
                                  </b-dropdown-item-button>
                                </template>
                              </div>
                            </b-dropdown>
                        </b-col>


                        <b-col sm="12" lg="4">
                          <b-dropdown variant="white" size="sm" style="width: 100%; margin: 0px;" no-caret>
                            <template slot="button-content">
                              <div style="font-size: 12px; white-space: pre-wrap;">
                                <i class="fa fa-check"></i>
                                {{ col.operation ? col.operation.translation[StoreLang] : '' }}
                              </div>
                            </template>
                            <template v-for="(oper, oper_ind) in d_operatorList">
                              <template v-if="['=', '<>', 'is_null', 'is_not_null'].indexOf(oper.value) !== -1">
                                <b-dropdown-item @click="f_selectOperationForColumn(col_ind, oper)">
                                  <i class="fa fa-check"></i>
                                  {{ oper.translation[StoreLang] }}
                                </b-dropdown-item>
                              </template>
                            </template>
                          </b-dropdown>
                        </b-col>


                        <b-col sm="12" lg="3">
                          <template v-if="col.selected_items.length > 0">
                            <template v-for="(selected_item, selected_item_ind) in col.selected_items">
                              <b-button variant="outline-warning" size="sm" style="font-size: 12px; margin: 2px; padding: 1px;" @click="f_deleteSelectedItem(col_ind, selected_item_ind)">
                                {{ selected_item.label }},
                              </b-button>
                            </template>
                          </template>
                          <template v-else>
                            {{ StoreLangTranslation.data['all'][StoreLang] }}
                          </template>
                        </b-col>

                      </b-row>
                    </template>


                    <template v-else-if="col.param_type === 'integer' || col.param_type === 'float'">
                      <b-row :style="col.use_as_stats === 'yes' ? 'padding: 3px; background-color: #e3f6ff; border-bottom: solid 1px rgb(227, 246, 255);' : 'padding: 3px; border-bottom: solid 1px rgb(227, 246, 255);'">

                        <b-col v-if=
                        "['wanalyzer_create_query'].indexOf(p_selectedProjectType) !== -1 || (['wanalyzer_dataset_stats_query', 'wanalyzer_dataset_filter'].indexOf(p_selectedProjectType) !== -1 && col.wai_output)" sm="12" lg="2" @click="f_selectColumnUseAsStats(col_ind)">
                          <i class="fa fa-check" :style="col.use_as_stats === 'yes' ? 'color: #009cff;' : 'color: grey;'"></i>
                        </b-col>

                        <b-col v-else sm="12" lg="2"> </b-col>

                        <b-col sm="12" lg="3" style="font-size: 12px; text-align: center;">
                           <strong>{{ col.name }}</strong>
                        </b-col>
                        <b-col sm="12" lg="4">
                          <b-dropdown variant="white" size="sm" style="width: 100%; margin: 0px;" no-caret>
                            <template slot="button-content">
                              <div style="font-size: 12px; white-space: pre-wrap;">
                                <i class="fa fa-check"></i>
                                {{ col.operation ? col.operation.translation[StoreLang] : '' }}
                              </div>
                            </template>
                            <template v-for="(oper, oper_ind) in d_operatorList">
                              <b-dropdown-item @click="f_selectOperationForColumn(col_ind, oper)">
                                <i class="fa fa-check"></i>
                                {{ oper.translation[StoreLang] }}
                              </b-dropdown-item>
                            </template>
                          </b-dropdown>
                        </b-col>
                        <b-col sm="12" lg="3">
                          <b-form-input type="number" style="font-size: 12px;" v-model="col.selected"></b-form-input>
                          <!-- <b-form-input type="number" style="font-size: 12px;" v-model="col.selected" @change="f_wanalyzerProjectStatisticsAnalyzeList()"></b-form-input> -->
                        </b-col>
                      </b-row>
                    </template>


                    <template v-else>
                      <b-row :style="col.use_as_stats === 'yes' ? 'padding: 3px; background-color: #e3f6ff; border-bottom: solid 1px rgb(227, 246, 255);' : 'padding: 3px; border-bottom: solid 1px rgb(227, 246, 255);'">

                        <b-col v-if=
                        "['wanalyzer_create_query'].indexOf(p_selectedProjectType) !== -1 || (['wanalyzer_dataset_stats_query', 'wanalyzer_dataset_filter'].indexOf(p_selectedProjectType) !== -1 && col.wai_output)" sm="12" lg="2" @click="f_selectColumnUseAsStats(col_ind)">
                          <i class="fa fa-check" :style="col.use_as_stats === 'yes' ? 'color: #009cff;' : 'color: grey;'"></i>
                        </b-col>

                        <b-col v-else sm="12" lg="2"> </b-col>

                        <b-col sm="12" lg="3" style="font-size: 12px; text-align: center;">
                           <strong>{{ col.name }}</strong>
                           <div>
                            <b-form-select v-if="d_project.column_list[col_ind].use_as_stats == 'yes'" v-model="d_project.column_list[col_ind].stats_data_type" style="font-size: 12px;">
                              <option style="font-size: 12px;" value="categorical" >
                                {{ StoreLangTranslation.data['categorical'][StoreLang] }}
                              </option>
                              <option style="font-size: 12px;" value="numeric" >
                                {{ StoreLangTranslation.data['numeric'][StoreLang] }}
                              </option>
                            </b-form-select>
                           </div>
                        </b-col>
                        <b-col sm="12" lg="4">
                          <b-dropdown variant="white" size="sm" style="width: 100%; margin: 0px;" no-caret>
                            <template slot="button-content">
                              <div style="font-size: 12px; white-space: pre-wrap;">
                                <i class="fa fa-check"></i>
                                {{ col.operation ? col.operation.translation[StoreLang] : '' }}
                              </div>
                            </template>
                            <template v-for="(oper, oper_ind) in d_operatorList">
                              <b-dropdown-item @click="f_selectOperationForColumn(col_ind, oper)">
                                <i class="fa fa-check"></i>
                                {{ oper.translation[StoreLang] }}
                              </b-dropdown-item>
                            </template>
                          </b-dropdown>
                        </b-col>
                        <b-col sm="12" lg="3">
                          <b-form-input type="text" style="font-size: 12px;" v-model="col.selected"></b-form-input>
                        </b-col>
                      </b-row>
                    </template>


                  </template>
                  <!-- <b-row>
                    <b-col sm="12" lg="6"> </b-col>
                    <b-col sm="12" lg="6" style="text-align: right;">
                      <template v-if="['wanalyzer_dataset_filter'].indexOf(p_selectedProjectType) !== -1">
                        <b-button variant="outline-primary" size="sm" @click="f_wanalyzerServicesTrigger()">
                          <i class="fa fa-send-o"></i> {{ StoreLangTranslation.data['analyze'][StoreLang] }}
                        </b-button>
                      </template>
                      <template v-else>
                        <b-button variant="outline-primary" size="sm" @click="f_wanalyzerProjectStatisticsAnalyzeList()">
                          <i class="fa fa-send-o"></i> {{ StoreLangTranslation.data['analyze'][StoreLang] }}
                        </b-button>
                      </template>
                    </b-col>
                  </b-row> -->
                </template>
              </b-col>
              <b-col sm="12" md="12" lg="8" style="border-left: solid 1px lightblue;">
                <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
                  <b-row>
                    <b-col sm="12" lg="4">
                      <h5> {{ StoreLangTranslation.data['results'][StoreLang] }} </h5>
                    </b-col>
                    <b-col sm="12" lg="2">
                      <template v-if="d_projectDataQueryLoading">
                          <b-spinner variant="primary" label="Spinning"></b-spinner>
                          {{ StoreLangTranslation.data['continuing'][StoreLang] }}
                      </template>
                      <template v-if="d_statsViewLoading">
                          <b-spinner variant="primary" label="Spinning">statistics loading</b-spinner>
                      </template>
                    </b-col>
                    <b-col sm="12" lg="3">
                      <!-- <b-form-checkbox v-model="d_autoLoading"> {{ StoreLangTranslation.data['auto_loading'][StoreLang] }} </b-form-checkbox> -->
                    </b-col>
                    <b-col sm="12" lg="3">
                      <template v-if="!d_autoLoading">
                        <b-button variant="outline-primary" size="sm" @click="f_wanalyzerServicesTrigger()">
                          <i class="fa fa-send-o"></i> {{ StoreLangTranslation.data['analyze'][StoreLang] }}
                        </b-button>
                      </template>
                    </b-col>
                  </b-row>
                </b-card-header>
                <b-row style="margin: 3px;">
                  <b-col sm="12" lg="12">
                    <b-tabs>
                      <!-- dataset sadece wanalyzer_dataset_filter modunda gösteriliyor  -->
                      <b-tab v-if="['wanalyzer_dataset_filter', 'wanalyzer_create_query'].indexOf(p_selectedProjectType) !== -1" :active="'dataset_view' === d_selectedTabItemId" @click="d_selectedTabItemId = 'dataset_view'">
                        <template slot="title">
                          <i class="fa fa-area-chart"></i>
                          <span v-if="'dataset_view' === d_selectedTabItemId" style="font-weight: bold;">
                          {{ StoreLangTranslation.data['dataset'][StoreLang] }}
                          </span>
                          <span v-else>
                          {{ StoreLangTranslation.data['dataset'][StoreLang] }}
                          </span>
                        </template>
                        <template v-if="'dataset_view' === d_selectedTabItemId">
                          <template v-if="d_datasetViewLoading">
                              <b-spinner variant="primary" label="Spinning"></b-spinner>
                          </template>
                          <template v-else>
                            <w-table2 style="transition: 2s; transform: none;" v-if="d_datasetWTableFilter && d_datasetShow" :key="'dataset_view'" :p_tableDetails="d_datasetWTableFilter"></w-table2>
                          </template>
                        </template>
                      </b-tab>
                      <!-- istatistik varsa her halükarda gösteriliyor. -->
                      <template v-for="(proj_item, proj_id) in d_statisticResults">
                        <template v-for="(stats_item, stats_id) in proj_item">
                          <template v-for="(analyze_item, analyze_index) in stats_item.analyze_list">
                            <b-tab :active="'analyze_' + proj_id + '_' + stats_id + '_' + analyze_index === d_selectedTabItemId" @click="d_selectedTabItemId = 'analyze_' + proj_id + '_' + stats_id + '_' + analyze_index">
                              <template slot="title">
                                <i class="fa fa-area-chart"></i>
                                <span v-if="'analyze_' + proj_id + '_' + stats_id + '_' + analyze_index === d_selectedTabItemId" style="font-weight: bold;">
                                {{ analyze_item.label }}
                                </span>
                                <span v-else>
                                {{ analyze_item.label }}
                                </span>
                              </template>
                              <template v-if="'analyze_' + proj_id + '_' + stats_id + '_' + analyze_index === d_selectedTabItemId">
                                <template v-if="d_statsViewLoading">
                                    <b-spinner variant="primary" label="Spinning">statistics loading</b-spinner>
                                </template>
                                <template v-else>
                                  <wanalyzer-analyze-view v-if="d_statsShow" :key="'analyze_' + proj_id + '_' + stats_id + '_' + analyze_index" :p_analyzeList="[analyze_item]" :p_authClientId="p_projectPermission.auth_data.client_id"></wanalyzer-analyze-view>
                                </template>
                              </template>
                            </b-tab>
                          </template>
                        </template>
                      </template>
                    </b-tabs>
                    <!-- <b-tabs>
                      <template v-for="(col, col_ind) in d_project.column_list">
                        <template v-if="col.wai_output">
                          <b-tab :active="col.id === d_selectedColumn" @click="d_selectedColumn = col.id">
                            <template slot="title">
                              <i class="fa fa-area-chart"></i>
                              <span v-if="col.id === d_selectedColumn" style="font-weight: bold;">
                              {{ col.name }}
                              </span>
                              <span v-else>
                              {{ col.name }}
                              </span>
                            </template>
                            <template v-if="col.id === d_selectedColumn">
                              {{ col }}
                            </template>
                          </b-tab>
                        </template>
                      </template>
                    </b-tabs> -->
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
        </template>
      </b-tab>


    </b-tabs>


    <b-modal v-if="d_showTableCellFullText" id="d_showTableCellFullText" v-model="d_showTableCellFullText" scrollable hide-footer title="Bilgi" centered header-bg-variant="info" header-text-variant="dark">
      <p class="my-4" style="word-wrap: break-word;">
        {{ d_selectedTableCellFullText }}
      </p>
    </b-modal>


    <b-modal v-if="d_showGraphicView" id="d_showGraphicView" v-model="d_showGraphicView" scrollable hide-footer title="Bilgi" centered header-bg-variant="info" header-text-variant="dark" size="xl">
      X: {{ chart_data.labels.length }},
      <select v-model="d_chartSize.chart_type" style="height: 30px; width: 100px;margin-right: 3px;">
        <option value="line">çizgi</option>
        <option value="bar">bar</option>
        <option value="scatter">nokta</option>
      </select>
      <select v-if="d_chartSize.y_mode ==='standart'" v-model="d_chartSize.x_mode" style="height: 30px; width: 100px;margin-right: 3px;">
        <option value="standart">x standart</option>
        <option value="x_ascending">x artan</option>
        <option value="x_descending">x azalan</option>
      </select>
      <select v-if="d_chartSize.x_mode ==='standart'" v-model="d_chartSize.y_mode" style="height: 30px; width: 100px;margin-right: 3px;">
        <option value="standart">y standart</option>
        <option value="y_ascending">y artan</option>
        <option value="y_descending">y azalan</option>
      </select>
      <select v-model="d_chartSize.x_interval_type" style="height: 30px; width: 100px;margin-right: 3px;">
        <option value="all">'x' tüm değerler</option>
        <option value="once_every_x_value">'x' her ... değerde bir</option>
      </select>
      <input v-if="d_chartSize.x_interval_type ==='once_every_x_value'" type="number" v-model="d_chartSize.x_interval" min="1" style="height: 30px; width: 100px;margin-right: 3px;" placeholder="x kaç değerde bir görülecek">
      <!--
        <div class="chart-wrapper" style="height: 100%;">
          <line-view :chart_data="chart_data" style="height:300px;" :height="300" />
        </div>
      -->
      <div v-if="d_showChartView" class="chart-wrapper" style="height: 100%;">
        <template v-if="d_chartSize.chart_type ==='line'">
          <line-view :chart_data="chart_data" :style="'height:' + d_chartSize.height.toString() +'px;'" :height="d_chartSize.height" :width="d_chartSize.width" />
        </template>
        <template v-if="d_chartSize.chart_type ==='bar'">
          <bar-view :chart_data="chart_data" :style="'height:' + d_chartSize.height.toString() +'px;'" :height="d_chartSize.height" :width="d_chartSize.width" />
        </template>
        <template v-if="d_chartSize.chart_type ==='scatter'">
          <scatter-view :chart_data="chart_data" :style="'height:' + d_chartSize.height.toString() +'px;'" :height="d_chartSize.height" :width="d_chartSize.width" />
        </template>
      </div>
    </b-modal>


    <b-modal v-if="d_mainRuleShowModal" id="d_mainRuleShowModal" v-model="d_mainRuleShowModal" scrollable hide-footer title="Ana Kural" centered header-bg-variant="info" header-text-variant="dark" size="xl">
      <div style="min-height: 500px;">
        <wdm-rule-a :data_type="d_mainRule.data_type" :option_data="d_mainRule.option_data" :prepare_data="d_mainRule.prepare_data" :query_list="d_mainRule.query_list" :compile_str_array="d_mainRule.compile_str_array" :compile_str_date="d_mainRule.compile_str_date" :wdmr_change="d_mainRule.wdmr_change" :p_wdmList="d_mainRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_mainRule.wps_data" :p_wdmRuleAsettings="d_mainRule.wra_settings" :compile_str_text="d_mainRule.compile_str_text"></wdm-rule-a>
        <b-row>
          <b-col sm="12" lg="6"></b-col>
          <b-col sm="12" lg="3">
            <b-button variant="outline-primary" size="sm" @click="d_mainRuleShowModal = false">
              <i class="fa fa-send-o"></i> {{ StoreLangTranslation.data['cancel'][StoreLang] }}
            </b-button>
          </b-col>
          <b-col sm="12" lg="3">
            <b-button variant="outline-primary" size="sm" @click="f_acceptMainRule()">
              <i class="fa fa-send-o"></i> {{ StoreLangTranslation.data['save'][StoreLang] }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>


  </div>
</template>

<script>
import { ClsRule } from '@/wam/modules/rule';
import LineView from '@/components/chart/LineView';
import ScatterView from '@/components/chart/ScatterView';
import BarView from '@/components/chart/BarView';
import {
  default as TimeLine
} from '@/components/widgets/TimeLine';
import {
  default as WTable2
} from '@/components/widgets/WTable2';
import {
  default as WanalyzerAnalyzeView
} from '@/components/widgets/WanalyzerAnalyzeView';
import {
  default as RuleQuery
} from '@/wam/components/rule/RuleQuery';
import {
  default as WdmRuleA
} from '@/wam/components/rule/WdmRuleA';
import {
  default as WanalyzerProjectColumn
} from '@/wam/components/wanalyzer/WanalyzerProjectColumn';
import moment from "moment";
import { ServiceFunctions } from '@/wam/modules/service';
import { data as operator_list } from '@/wam/options/operator_list';
import { data as stats_wdm } from '@/wam/options/stats_wdm';
import { mapGetters } from 'vuex';
export default {
  name: 'WanalyzerDatasetAnalyze',
  computed: {
    ...mapGetters({
      StoreLangTranslation: 'StoreLangTranslation',
      StoreDevice: 'StoreDevice',
      StoreUser: 'StoreUser',
      StoreLoading: 'StoreLoading',
      StoreModal: 'StoreModal',
      StoreLang: 'StoreLang',
    })
  },
  components: {
    WTable2,
    WanalyzerAnalyzeView,
    TimeLine,
    LineView,
    BarView,
    ScatterView,
    WdmRuleA,
    RuleQuery,
    WanalyzerProjectColumn,
  },
  props: {
    p_projectId: {
      type: String,
      required: false
    },
    p_statsId: {
      type: String,
      required: false
    },
    p_selectedProjectType: {
      type: String,
      required: false
    },
    p_projectPermission: {
      type: Object,
      required: false
    },
    f_getUserAuthorizationList: {
      type: Function,
      required: false
    },
    p_fullScreen: {
      type: Boolean,
      required: false
    }
  },
  data: () => {
    return {
      d_backupProjectPermission: '',
      d_columnList: [],
      d_columnListLoading: false,
      d_mainRuleShowModal: false,
      d_prepareRuleNew: {
        'date_ability': '',
        'wdmr_change': { 'rule': 0 },
        'data_type': '',
        'option_data': {},
        'prepare_data': {},
        'query_list': [],
        'compile_str_date': [],
        'compile_str_text': [],
        'compile_str_array': [],
        'wra_settings': {
          'mode': 'param',
          'options': [
            { 'label': 'PARAM', 'value': 'param' },
            { 'label': 'PARAM & QUERY', 'value': 'param_query' }
          ]
        },
        'wps_data': [{
          'operation': '',
          'selected_value': '',
          'loc': [],
          'val_list': [],
          'selected_wdm': '',
          'd_level': 0,
          'd_level_count': 0,
          'significant': 'true'
        }],
        'wdm_list': []
      },
      d_timerControlProjectUpdate: { 'timer': 0, 'status': 'passive', 'max': 30000, 'interval': 5000, 'check_count_after_not_continue': 0 },
      d_projectDataQueryLoading: false,
      d_projectSaveLoading: false,
      d_selectedTab: 'data',
      d_autoLoading: false,
      d_projectColumnListLoading: false,
      d_datasetViewLoading: false,
      d_statsViewLoading: false,
      d_datasetWTable: '',
      d_datasetWTableFilter: '',
      d_statsShow: true,
      d_datasetShow: true,
      d_selectedTabItemId: '',
      d_statisticResults: {},
      d_selectedColumn: '',
      d_operatorList: operator_list,
      d_statsWdm: stats_wdm,
      d_showChartView: true,
      d_showGraphicView: false,
      d_chartSize: {
        'height': 0,
        'width': 0,
        'x_mode': 'standart',
        'y_mode': 'standart',
        'x_interval_type': 'all',
        'x_interval': 1,
        'chart_type': ''
      },
      chart_data: {},
      d_resetChartData: {},
      d_tableShow: true,
      d_tablePagination: {
        'start': 0,
        'end': 100,
        'current': 1,
        'perpage': 100,
        'all_count': 0
      },
      d_searchTextRow: '',
      d_fullScreen: false,
      d_statsList: {
        'analyze_list': [],
        'all_count': 0,
        'filter_stats': [],
        'filter_analyze_types': [],
        'selected_columns': []
      },
      d_projectId: '',
      d_statsId: '',
      d_filterData: {
        'selected_columns': [],
        'selected_stats': '',
        'selected_p_value': '',
        'selected_analyze_types': '',
        'user_score_list': '',
        'pagination': {
          'start': 0,
          'end': 1,
          'current': 1,
          'perpage': 1,
          'all_count': 0
        },
      },
      d_timeLineYearMonthMode: { 'year': 0, 'month': 0 },
      d_timeLineLeftToRight: [],
      mother_children: {
        'change_status': 0,
        'forceUpdate': 0
      },
      d_timeLineMode: false,
      d_isThereInputColumn: false,
      d_selectedTableCellFullText: '',
      d_showTableCellFullText: false,
      d_analyzeContinuing: false,
      d_sqlName: '',
      d_showSqlName: true,
      d_clientIdShow: true,
      d_mainRule: {},
      d_project: { 'details': '', 'column_list': [] },
      d_resetProject: { 'details': '', 'column_list': [] },
      d_clientId: '',
      d_idType: '',
      d_wdmrId: '',
    }
  },
  created () {
    if (this.p_projectPermission) {
      this.d_backupProjectPermission = JSON.parse(JSON.stringify(this.p_projectPermission));
    }
    // console.log('this.p_projectPermission ', this.p_projectPermission);
    if (this.p_projectId) {
      this.d_projectId = this.p_projectId;
    } else if (!this.p_projectId) {
      if (this.$route.query.project_id !== undefined) {
        this.d_projectId = this.$route.query.project_id;
      }
    }

    if (this.p_selectedProjectType === 'wanalyzer_create_query') {
      this.d_selectedTab = 'general_settings';
      this.d_selectedTabItemId = 'dataset_view';
      if (this.p_projectPermission.layer_tree_list_set && this.p_projectPermission.layer_tree_list_set.value) {
        this.f_layerTreeWdmGet(this.p_projectPermission.layer_tree_list_set.value, this.p_projectPermission.layer_list);
      }
      if (this.d_projectId) {
        this.f_wanalyzerProjectDetailsColumnsWaiGet();
        this.f_wanalyzerProjectDatasetGet('dataset_table');
        this.f_wanalyzerProjectColumnDataList();
      }

    } else if (this.p_selectedProjectType === 'wanalyzer_dataset_filter') {
      this.d_selectedTab = 'dataset_filter';
      this.d_selectedTabItemId = 'dataset_view';
      if (this.d_projectId) {
        this.f_wanalyzerProjectDetailsColumnsWaiGet();
      }
    } else if (this.p_selectedProjectType === 'wanalyzer_dataset_stats_query') {
      this.d_selectedTab = 'dataset_filter';
      this.d_selectedTabItemId = '';
      this.f_wanalyzerProjectDetailsColumnsWaiGet();
    }


  },
  methods: {
    f_selectLocation: function (location_index) {
      if (this.p_projectPermission.location_list[location_index].selected) {
        this.p_projectPermission.location_list[location_index].selected = 0;
        for (let server_index in this.p_projectPermission.location_list[location_index].server_list) {
          this.p_projectPermission.location_list[location_index].server_list[server_index].selected = 0;
        }
      } else {
        this.p_projectPermission.location_list[location_index].selected = 1;
        for (let server_index in this.p_projectPermission.location_list[location_index].server_list) {
          this.p_projectPermission.location_list[location_index].server_list[server_index].selected = 1;
        }
      }
      this.$forceUpdate();
    },
    f_selectServer: function (location_index, server_index) {
      if (this.p_projectPermission.location_list[location_index].server_list[server_index].selected) {
        this.p_projectPermission.location_list[location_index].server_list[server_index].selected = 0;
      } else {
        this.p_projectPermission.location_list[location_index].server_list[server_index].selected = 1;
      }
      this.$forceUpdate();
    },
    f_acceptMainRule: function () {
      let parenthesis_control = ClsRule.f_controlParanthesisDesign(this.d_mainRule.compile_str_array);
      // console.log('parenthesis_control : ', parenthesis_control);
      if (parenthesis_control) {
        if (this.d_mainRule.wra_settings.mode === 'param') {
          ClsRule.f_paramToQuery(this.d_mainRule.query_list, this.d_mainRule.compile_str_array, this.d_mainRule.wps_data);
        }
        this.p_projectPermission.main_rule_algorithm_set = {
          'query_list': this.d_mainRule.query_list,
          'compile_str_array': this.d_mainRule.compile_str_array,
          'compile_str_date': this.d_mainRule.compile_str_date,
          'compile_str_text': this.d_mainRule.compile_str_text
        };
        this.d_mainRuleShowModal = false;
      } else {
        alert(this.StoreLangTranslation.data['please_check_parenthesis'][this.StoreLang]);
      }
    },
    f_editMainRuleAlgorithm: function () {
      this.d_mainRule = JSON.parse(JSON.stringify(this.d_prepareRuleNew));
      for (let i in this.p_projectPermission.layer_list) {
        let wdm_name = this.p_projectPermission.layer_list[i]['layer']['name'][this.StoreLang];
        let wdm_key = this.p_projectPermission.layer_list[i]['layer']['value'];
        let new_wdm_item = {'type': wdm_key, 'value': wdm_key, 'label': wdm_name};
        this.d_mainRule.wdm_list.push(new_wdm_item);
      }
      if (this.p_projectPermission.main_rule_algorithm_set && this.p_projectPermission.main_rule_algorithm_set.query_list && this.p_projectPermission.main_rule_algorithm_set.query_list.length > 0) {
        this.d_mainRule.compile_str_array = JSON.parse(JSON.stringify(this.p_projectPermission.main_rule_algorithm_set.compile_str_array));
        this.d_mainRule.compile_str_date = JSON.parse(JSON.stringify(this.p_projectPermission.main_rule_algorithm_set.compile_str_date));
        this.d_mainRule.compile_str_text = JSON.parse(JSON.stringify(this.p_projectPermission.main_rule_algorithm_set.compile_str_text));
        this.d_mainRule.query_list = JSON.parse(JSON.stringify(this.p_projectPermission.main_rule_algorithm_set.query_list));
      }
      this.d_mainRuleShowModal = true;
    },
    f_layerTreeWdmGet: function (layer_tree_type, layer_list) {
      // Katman ağaçlarına ait wdm veri modelleri getiriliyor.
      // this.d_projectDataQueryLoading = true;
      // layer_list = [
      //   {
      //     "layer": {"name": {"en": "patient", "tr": "hasta"}, "value": "1"},
      //     "parameter_usage_type": {"name": {"en": "all", "tr": "hepsi"}, "value": "0"},
      //     "parameter_list": ""
      //   },
      //   {
      //     "layer": {"name": {"en": "patient / visit", "tr": "hasta / başvuru"}, "value": "2"},
      //     "parameter_usage_type": {"name": {"en": "all", "tr": "hepsi"}, "value": "0"},
      //     "parameter_list": ""
      //   },
      //   {
      //     "layer": {"name": {"en": "patient / visit / diagnosis", "tr": "hasta / başvuru / tanı"}, "value": "3"},
      //     "parameter_usage_type": {"name": {"en": "all", "tr": "hepsi"}, "value": "0"},
      //     "parameter_list": ""
      //   }
      // ],
      let query = '';
      let data = {'wdm_list': []};
      for (let i in layer_list) {
        let wdm_key = layer_list[i]['layer']['value'];
        data.wdm_list.push({'layer_tree_type': layer_tree_type, 'key': wdm_key});
      }
      ServiceFunctions.layer_tree_wdm_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.list) {
              localStorage.setItem('wdm_list', JSON.stringify(resp.data.list));
            }
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
            // this.d_projectDataQueryLoading = false;
          }
        });
    },
    f_createRuleAlgorithm: function (use_column_object) {
      let query_compile_str_array = ["("];
      let any_rule_columns_filled = false;
      let rule_data = {
        "query_list": [],
        "compile_str_array": [],
        "compile_str_text": [],
        "compile_str_date": []
      };
      for (let i in use_column_object.column_list) {
        if (['is_not_null'].indexOf(use_column_object.column_list[i].operation.value) !== -1) {
          let new_field_data = {
              "loc": [
                {"label": "Genel", "value": "general", "type": "group"},
                {"label": use_column_object.column_list[i].name, "value": use_column_object.column_list[i].index, "type": use_column_object.column_list[i].param_type}
              ],
              "using_data_if_not_reaching_record_data": "true",
              "val_list": [],
              "values": [],
              "significant": "true",
              "value_type": use_column_object.column_list[i].param_type,
              "operation": ""
          };
          let new_query_data = {
            "time_of_value": "any_value",
            "data_type": "old_recorded_data",
            "compile_str_array": ["(", 0, ")"],
            "fields": [],
            "compile_str": "",
            "significant": "true",
            "date": "1",
            "type": {"value": use_column_object.column_list[i].index, "label": use_column_object.column_list[i].name }
          };
          new_query_data.fields.push(new_field_data);
          rule_data.query_list.push(new_query_data);
          if (any_rule_columns_filled) {
            query_compile_str_array.push('and');
          }
          query_compile_str_array.push(rule_data.query_list.length - 1);
          any_rule_columns_filled = true;
        } else if (['is_null'].indexOf(use_column_object.column_list[i].operation.value) !== -1) {
          let new_field_data = {
              "loc": [
                {"label": "Genel", "value": "general", "type": "group"},
                {"label": use_column_object.column_list[i].name, "value": use_column_object.column_list[i].index, "type": use_column_object.column_list[i].param_type}
              ],
              "using_data_if_not_reaching_record_data": "true",
              "val_list": [],
              "values": [],
              "significant": "false",
              "value_type": use_column_object.column_list[i].param_type,
              "operation": ""
          };
          let new_query_data = {
            "time_of_value": "any_value",
            "data_type": "old_recorded_data",
            "compile_str_array": ["(", 0, ")"],
            "fields": [],
            "compile_str": "",
            "significant": "true",
            "date": "1",
            "type": {"value": use_column_object.column_list[i].index, "label": use_column_object.column_list[i].name }
          };
          new_query_data.fields.push(new_field_data);
          rule_data.query_list.push(new_query_data);
          if (any_rule_columns_filled) {
            query_compile_str_array.push('and');
          }
          query_compile_str_array.push(rule_data.query_list.length - 1);
          any_rule_columns_filled = true;
        } else {
          if (['select', 'radiogroup'].indexOf(use_column_object.column_list[i].param_type) !== -1) {
            if (['=', '<>'].indexOf(use_column_object.column_list[i].operation.value) !== -1) {
              if (use_column_object.column_list[i].selected_items && use_column_object.column_list[i].selected_items.length > 0) {
                let values_list = [];
                for (let op_index in use_column_object.column_list[i].selected_items) {
                  values_list.push(use_column_object.column_list[i].selected_items[op_index].value);
                }
                let new_field_data = {
                    "loc": [
                      {"label": "Genel", "value": "general", "type": "group"},
                      {"label": use_column_object.column_list[i].name, "value": use_column_object.column_list[i].index, "type": use_column_object.column_list[i].param_type}
                    ],
                    "using_data_if_not_reaching_record_data": "true",
                    "val_list": use_column_object.column_list[i].selected_items,
                    "values": values_list,                    
                    "significant": "true",
                    "value_type": use_column_object.column_list[i].param_type,
                    "operation": use_column_object.column_list[i].operation.value
                };
                let new_query_data = {
                  "time_of_value": "any_value",
                  "data_type": "old_recorded_data",
                  "compile_str_array": ["(", 0, ")"],
                  "fields": [],
                  "compile_str": "",
                  "significant": "true",
                  "date": "1",
                  "type": {"value": use_column_object.column_list[i].index, "label": use_column_object.column_list[i].name }
                };
                new_query_data.fields.push(new_field_data);
                rule_data.query_list.push(new_query_data);
                if (any_rule_columns_filled) {
                  query_compile_str_array.push('and');
                }
                query_compile_str_array.push(rule_data.query_list.length - 1);
                any_rule_columns_filled = true;
              }
            }
          } else {
            if (use_column_object.column_list[i].selected) {
              let values_list = [];
              values_list.push(use_column_object.column_list[i].selected);
              let new_field_data = {
                  "loc": [
                    {"label": "Genel", "value": "general", "type": "group"},
                    {"label": use_column_object.column_list[i].name, "value": use_column_object.column_list[i].index, "type": use_column_object.column_list[i].param_type}
                  ],
                  "using_data_if_not_reaching_record_data": "true",
                  "val_list": values_list,
                  "values": values_list,
                  "significant": "true",
                  "value_type": use_column_object.column_list[i].param_type,
                  "operation": use_column_object.column_list[i].operation.value
              };
              let new_query_data = {
                "time_of_value": "any_value",
                "data_type": "old_recorded_data",
                "compile_str_array": ["(", 0, ")"],
                "fields": [],
                "compile_str": "",
                "significant": "true",
                "date": "1",
                "type": {"value": use_column_object.column_list[i].index, "label": use_column_object.column_list[i].name }
              };
              new_query_data.fields.push(new_field_data);
              rule_data.query_list.push(new_query_data);
              if (any_rule_columns_filled) {
                query_compile_str_array.push('and');
              }
              query_compile_str_array.push(rule_data.query_list.length - 1);
              any_rule_columns_filled = true;
            }
          }
        }
      }
      if (any_rule_columns_filled) {
        query_compile_str_array.push(')');
        rule_data.compile_str_array = query_compile_str_array;
      }
      return rule_data;
    },
    f_wanalyzerProjectDatasetGet: function (table_type='') {
      this.d_datasetViewLoading = true;
      let query = 'project_id=' + this.d_projectId ;
      if (this.p_projectPermission) {
        query += '&auth_client_id=' + this.p_projectPermission.auth_data.client_id;
      }

      let wai_columns = 'yes';
      if (['wanalyzer_create_query'].indexOf(this.p_selectedProjectType) !== -1) {
        // wanalyzer_create_query modunda tüm kolonlara yetkilidir.
        wai_columns = 'no';
      }
      let data = {
        'pagination': '',
        'filter_rule': {},
        'wai_columns': wai_columns
      };
      if (table_type === 'dataset_filter_table') {
        this.d_datasetWTableFilter = '';
        data.filter_rule = this.f_createRuleAlgorithm(this.d_project);
      } else if (table_type === 'dataset_table') {
        this.d_datasetWTable = '';
        data.filter_rule = {};
      }
      ServiceFunctions.wanalyzer_project_dataset_get(query, data)
        .then(resp => {
          this.d_datasetViewLoading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (table_type === 'dataset_filter_table') {
              this.d_datasetWTableFilter = resp.data.wtable;
            } else if (table_type === 'dataset_table') {
              this.d_datasetWTable = resp.data.wtable;
            }
            if (['wanalyzer_dataset_filter', 'wanalyzer_create_query'].indexOf(this.p_selectedProjectType) !== -1) {
              this.d_selectedTabItemId = 'dataset_view';
            }
            // this.f_datasetShowRefresh();
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_wanalyzerProjectColumnDataList: function () {
      this.d_columnListLoading = true;
      let query = 'project_id=' + this.d_projectId ;
      let data = {};
      ServiceFunctions.wanalyzer_project_column_data_list(query, data)
        .then(resp => {
          this.d_columnListLoading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_columnList = resp.data.list;
            // Kolon listesi kaydolduğunda dataset filter alanındaki kolonlarında güncellenmesini sağlıyoruz.
            this.f_wanalyzerProjectDetailsColumnsWaiGet();
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_wanalyzerProjectDetailsColumnsWaiGet: function () {
      this.d_projectColumnListLoading = true;
      let query = 'project_id=' + this.d_projectId ;
      let column_type = 'wai';
      if (this.p_projectPermission) {
        column_type = 'all';
        query += '&auth_client_id=' + this.p_projectPermission.auth_data.client_id;
      }
      query += '&column_type=' + column_type;
      let data = {};
      ServiceFunctions.wanalyzer_project_details_columns_wai_get(query, data)
        .then(resp => {
          this.d_projectColumnListLoading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_project.details = resp.data.details;
            for (let i in resp.data.column_list) {
              if (!this.d_selectedColumn) {
               if (resp.data.column_list[i].wai_output) {
                this.d_selectedColumn = resp.data.column_list[i].id;
               } 
              }
              resp.data.column_list[i].operation = {
                'value': '=',
                'translation': {
                  'tr': 'eşittir',
                  'en': 'equal to'
                }
              };
              if (resp.data.column_list[i].param_type === 'select' || resp.data.column_list[i].param_type === 'radiogroup') {
                resp.data.column_list[i].selected_items = [];
              } else if (resp.data.column_list[i].param_type === 'integer' || resp.data.column_list[i].param_type === 'float') {
                resp.data.column_list[i].selected = '';
              }
            }
            this.d_project.column_list = resp.data.column_list;
            for (let i in this.d_project.column_list) {
              if (this.d_project.column_list[i].wai_input === 1) {
                  this.d_isThereInputColumn = true;
                  break;
              }
            }
            this.d_resetProject = JSON.parse(JSON.stringify(this.d_project));
            if (this.d_autoLoading) {
              this.f_wanalyzerServicesTrigger();
            }
            this.f_controlAndCreateTimer();
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_wanalyzerDataQuery: function (process_type) {
      if (confirm(this.StoreLangTranslation.data[process_type][this.StoreLang]) + ' ? ') {
        this.d_projectDataQueryLoading = true;
        let query = 'project_id=' + this.d_projectId;
        query += '&process_type=' + process_type;
        let data = {};
        ServiceFunctions.wanalyzer_data_query(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              // İlk proje güncelleme durumu takibini 10 saniye sonra tetikliyoruz. Çünkü direkt tetiklediğimizde, henüz durumu continuing olmamış oluyor.
              this.d_timerControlProjectUpdate.status = 'active';
              setTimeout(function () {
                this.f_wanalyzerProjectDetailsGet();
              }.bind(this), 10000)
            } else {
              let txt = 'status_code = ' + resp.data.status_code + '\n';
              txt += 'status_message = ' + resp.data.status_message;
              alert(txt);
              this.d_projectDataQueryLoading = false;
            }
          });
      }
    },
    f_wanalyzerProjectDetailsGet: function () {

      let query = 'project_id=' + this.d_projectId ;
      let column_type = 'wai';
      if (['wanalyzer_create_query'].indexOf(this.p_selectedProjectType) !== -1) {
        column_type = 'all';
      }
      query += '&column_type=' + column_type;
      let data = {};
      ServiceFunctions.wanalyzer_project_details_columns_wai_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_project.details = resp.data.details;
            this.f_controlAndCreateTimer();
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
            this.d_projectDataQueryLoading = false;
          }
        });
    },
    f_controlAndCreateTimer: function () {
      // console.log('this.d_project.details  : ', this.d_project.details);
      // console.log('f_controlAndCreateTimer : ');
      let data_updating_status = '';
      try {
        data_updating_status = this.d_project.details.data_updating_status;
      } catch (err) {}
      // console.log('data_updating_status                                            : ', data_updating_status);
      // console.log('this.d_timerControlProjectUpdate.status                         : ', this.d_timerControlProjectUpdate.status);
      // console.log('this.d_timerControlProjectUpdate.interval                       : ', this.d_timerControlProjectUpdate.interval);
      // console.log('this.d_timerControlProjectUpdate.check_count_after_not_continue : ', this.d_timerControlProjectUpdate.check_count_after_not_continue);
      if (data_updating_status === 'continuing') {
        this.d_projectDataQueryLoading = true;
        this.d_timerControlProjectUpdate.status = 'active';
        this.d_timerControlProjectUpdate.interval += 5000;
        if (this.d_timerControlProjectUpdate.interval > this.d_timerControlProjectUpdate.max) {
          this.d_timerControlProjectUpdate.interval = this.d_timerControlProjectUpdate.max;
          // maksimum intervale ulaşınca bu döngüde artık dataseti her timer döngüsü tamamlandığında getiriyoruz.
          this.f_wanalyzerProjectDatasetGet('dataset_table');
        }
        setTimeout(function () {
          this.f_wanalyzerProjectDetailsGet();
        }.bind(this), this.d_timerControlProjectUpdate.interval)

      } else {
        if (this.d_timerControlProjectUpdate.status === 'active') {
          if (this.d_timerControlProjectUpdate.check_count_after_not_continue >= 2) {
            this.d_timerControlProjectUpdate.timer = 0;
            this.d_timerControlProjectUpdate.interval = 0;
            this.d_timerControlProjectUpdate.check_count_after_not_continue = 0;
            this.d_timerControlProjectUpdate.status = 'passive';
            // window.clearInterval(this.d_timerControlProjectUpdate.timer);
            this.f_wanalyzerProjectDatasetGet('dataset_table');
            this.f_wanalyzerServicesTrigger();
            this.d_projectDataQueryLoading = false;
          } else {
            this.d_timerControlProjectUpdate.check_count_after_not_continue += 1;
            // 5 saniye sonra yeniden projeyi kontrol ediyoruz.
            setTimeout(function () {
              this.f_wanalyzerProjectDetailsGet();
            }.bind(this), 5000)
          }
        }
      }
    },
    f_projectSaveEligibleCheck: function () {
      let disable_status = false;
      // console.log('this.p_projectPermission.wanalyzer_project_name : ', this.p_projectPermission.wanalyzer_project_name);
      // console.log('this.p_projectPermission.case_limit_count_set   : ', this.p_projectPermission.case_limit_count_set);
      // console.log('this.p_projectPermission.case_limit_count       : ', this.p_projectPermission.case_limit_count);

      if (!this.p_projectPermission.wanalyzer_project_name) {
        // console.log('1');
        disable_status = true;
      }
      if (['wanalyzer_create_query'].indexOf(this.p_selectedProjectType) !== -1) {
        if (!disable_status) {
          if (this.p_projectPermission.case_limit_count_set && this.p_projectPermission.case_limit_count) {
            if (parseInt(this.p_projectPermission.case_limit_count_set) > parseInt(this.p_projectPermission.case_limit_count)) {
              // console.log('2');
              disable_status = true;
            }
          }
        }

        // Ana kural sorgusu oluşturmak zorunlu. Çünkü filtresiz veri çekimine izin verilmemesi gerekiyor.
        if (!disable_status) {
          let query_list = [];
          try {
            query_list = this.p_projectPermission.main_rule_algorithm_set.query_list;
          } catch(err){}
          if (!query_list) {
            disable_status = true;
          } else {
            if (query_list.length === 0) {
              disable_status = true;
            }
          }
        }

        if (!disable_status) {
          let location_list = JSON.parse(JSON.stringify(this.p_projectPermission.location_list))
          let new_location_list = [];
          for (let i in location_list) {
            if (location_list[i].selected) {
              let new_loc = {
                'label': location_list[i]['name'][this.StoreLang],
                'value': location_list[i]['value'],
                'server_list': []
              }
              if (location_list[i].server_list && location_list[i].server_list.length > 0) {
                for (let server_index in location_list[i].server_list) {
                  if (location_list[i].server_list[server_index].selected) {
                    let new_server_item = {
                      'value': location_list[i].server_list[server_index].value,
                      'label': location_list[i].server_list[server_index].name[this.StoreLang],
                    }
                    new_loc.server_list.push(new_server_item);
                  }
                }
              }
              // console.log('new_loc.server_list: ', new_loc.server_list);
              // sunucu seçimi zorunlu hale getirildi.
              if (new_loc.server_list.length > 0) {
                new_location_list.push(new_loc);
              }
            }
          }
          if (new_location_list.length === 0) {
            disable_status = true;
          }
        }

      }

      // console.log('disable_status ', disable_status);
      return disable_status;
    },
    f_wanalyzerColumnSave: function () {
      let query = 'project_id=' + this.p_projectPermission.wanalyzer_project_id;
      let data = {
        'column_list': this.d_columnList,
      };
      // console.log('this.d_columnList ', this.d_columnList);
      ServiceFunctions.wanalyzer_project_column_save(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            //
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_wanalyzerProjectSave: function () {
      // aşağıda ana katmanın değiştirilme durumu kontrol ediliyor.
      // ana katman değiştirildiğinde, kolon verileri içindeki ana katman key de değiştirilerek kaydediliyor.
      if (this.d_backupProjectPermission.main_layer && this.p_projectPermission.wanalyzer_project_id) {
        if (this.d_backupProjectPermission.main_layer !== this.p_projectPermission.main_layer) {
          if (this.d_columnList.length > 0) {
            for (let col_index in this.d_columnList) {
              if (this.d_columnList[col_index].type === 'wdm') {
                if (this.d_columnList[col_index].layer_rules) {
                  let col_layer_key = Object.keys(this.d_columnList[col_index].layer_rules)[0];
                  this.d_columnList[col_index].layer_rules[this.p_projectPermission.main_layer] = JSON.parse(JSON.stringify(this.d_columnList[col_index].layer_rules[col_layer_key]))
                  this.$delete(this.d_columnList[col_index].layer_rules, col_layer_key);
                }
              }
            }
            this.f_wanalyzerColumnSave();
          }
        }
      }
      let case_limit_warning_type = '';
      // maksimum satır limiti kontrolü yapılıyor.
      if (this.p_projectPermission.case_limit_count_set && this.p_projectPermission.case_limit_count) {
        if (this.p_projectPermission.case_limit_count_set > this.p_projectPermission.case_limit_count) {
          case_limit_warning_type = 'case_limit_max_value';
        }
      } else {
        this.p_projectPermission.case_limit_count_set = this.p_projectPermission.case_limit_count;
      }
      if (case_limit_warning_type) {
        alert(this.StoreLangTranslation.data[case_limit_warning_type][this.StoreLang]);
      } else {
        this.d_projectSaveLoading = true;
        let query = '';
        if (this.d_projectId) {
          query += 'project_id=' + this.d_projectId;
          if (this.p_projectPermission.auth_data && this.p_projectPermission.auth_data.client_id) {
            query += '&user_auth_client_id=' + this.p_projectPermission.auth_data.client_id;
          }
        } else {
          query += 'user_auth_client_id=' + this.p_projectPermission.auth_data.client_id;
        }
        let location_list = JSON.parse(JSON.stringify(this.p_projectPermission.location_list))
        let new_location_list = [];
        for (let i in location_list) {
          if (location_list[i].selected) {
            let new_loc = {
              'label': location_list[i]['name'][this.StoreLang],
              'value': location_list[i]['value'],
              'server_list': []
            }
            if (location_list[i].server_list && location_list[i].server_list.length > 0) {
              for (let server_index in location_list[i].server_list) {
                if (location_list[i].server_list[server_index].selected) {
                  let new_server_item = {
                    'value': location_list[i].server_list[server_index].value,
                    'label': location_list[i].server_list[server_index].name[this.StoreLang],
                  }
                  new_loc.server_list.push(new_server_item);
                }
              }
            }
            new_location_list.push(new_loc);
          }
        }

        // let use_main_rule = JSON.parse(JSON.stringify());
        // console.log('this.p_projectPermission.main_rule_algorithm : ', this.p_projectPermission.main_rule_algorithm);
        // if (this.p_projectPermission.main_rule_algorithm && this.p_projectPermission.main_rule_algorithm.query_list.length > 0) {
        //   use_main_rule.compile_str_array.push('and');
        //   for (let q_index in this.p_projectPermission.main_rule_algorithm.query_list) {
        //     let q_data = this.p_projectPermission.main_rule_algorithm.query_list[q_index];
        //     use_main_rule.query_list.push(q_data);
        //   }
        //   for (let compile_index in this.p_projectPermission.main_rule_algorithm.compile_str_array) {
        //     if (typeof this.p_projectPermission.main_rule_algorithm.compile_str_array[compile_index] === 'number') {
        //       let compile_use = parseInt(this.p_projectPermission.main_rule_algorithm.compile_str_array[compile_index]);
        //       let calculated_query_compile_index = compile_use + this.p_projectPermission.main_rule_algorithm_set.query_list.length;
        //       use_main_rule.compile_str_array.push(calculated_query_compile_index);
        //     } else {
        //       use_main_rule.compile_str_array.push(this.p_projectPermission.main_rule_algorithm.compile_str_array[compile_index]);
        //     }
        //   }
        // }

        // console.log('use_main_rule ', use_main_rule);

        let data = {
          'project_name': this.p_projectPermission.wanalyzer_project_name,
          'case_limit_count_set': this.p_projectPermission.case_limit_count_set,
          'location_list': new_location_list,
          'main_rule_algorithm': this.p_projectPermission.main_rule_algorithm_set,
        };
        if (this.p_projectPermission.main_layer) {
          for (let i in this.p_projectPermission.layer_list) {
            if (this.p_projectPermission.layer_list[i].layer.value === this.p_projectPermission.main_layer) {
              data.main_layer = {
                'value': this.p_projectPermission.main_layer,
                'label': this.p_projectPermission.layer_list[i].layer.name[this.StoreLang]
              };
              break
            }
          }
        }
        if (this.p_projectPermission.layer_tree_list_set) {
          data.layer_tree = {
            'value': this.p_projectPermission.layer_tree_list_set.value,
            'label': this.p_projectPermission.layer_tree_list_set.name[this.StoreLang]
          };
        }
        ServiceFunctions.wanalyzer_project_save(query, data)
          .then(resp => {
            this.d_projectSaveLoading = false;
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              if (resp.data.project_id && !this.d_projectId) {
                if (this.f_getUserAuthorizationList) {
                  this.f_getUserAuthorizationList();
                }
                this.p_projectPermission.wanalyzer_project_id = resp.data.project_id;
                let new_query = {
                  'path': this.$route.path,
                  'query': {
                    'project_id': this.p_projectPermission.wanalyzer_project_id,
                    'project_type': this.p_selectedProjectType
                  }
                };
                this.$router.push(new_query);
                this.d_projectId = this.p_projectPermission.wanalyzer_project_id;
                this.f_wanalyzerProjectDetailsColumnsWaiGet();
                this.f_wanalyzerProjectColumnDataList();
              }
            } else {
              let txt = 'status_code = ' + resp.data.status_code + '\n';
              txt += 'status_message = ' + resp.data.status_message;
              alert(txt);
            }
          });
      }
    },
    f_deleteSelectedItem: function (col_index, selected_item_ind) {
      this.d_project.column_list[col_index].selected_items.splice(selected_item_ind, 1);
      this.$forceUpdate();
      if (this.d_autoLoading) {
        this.f_wanalyzerServicesTrigger();
      }
    },
    f_wanalyzerServicesTrigger: function () {
      this.f_wanalyzerProjectStatisticsAnalyzeList();
      if (['wanalyzer_dataset_filter', 'wanalyzer_create_query'].indexOf(this.p_selectedProjectType) !== -1) {
        this.f_wanalyzerProjectDatasetGet('dataset_filter_table');
      }
    },
    f_selectOperationForColumn: function (col_index, operation) {
      this.d_project.column_list[col_index].operation = operation;
      // null yönetiminde değerlerin seçili olması gerekmiyor.
      if (['is_null', 'is_not_null'].indexOf(operation.value) !== -1) {
        if (this.d_project.column_list[col_index].selected_items && this.d_project.column_list[col_index].selected_items.length > 0) {
          this.d_project.column_list[col_index].selected_items = [];
        }
        if (this.d_project.column_list[col_index].selected) {
          this.d_project.column_list[col_index].selected = '';
        }
      }
      this.$forceUpdate();
      if (this.d_autoLoading) {
        this.f_wanalyzerServicesTrigger();
      }
    },
    f_resetProjectData: function () {
      this.d_project = JSON.parse(JSON.stringify(this.d_resetProject));
      this.$forceUpdate();
      if (this.d_autoLoading) {
        this.f_wanalyzerServicesTrigger();
      }
    },
    f_statsShowRefresh: function () {
      this.d_statsShow = false;
      setTimeout(function () {
        this.d_statsShow = true;
        this.$forceUpdate();
      }.bind(this), 1);
    },
    f_datasetShowRefresh: function () {
      this.d_datasetShow = false;
      setTimeout(function () {
        this.d_datasetShow = true;
        this.$forceUpdate();
      }.bind(this), 1);
    },
    f_addToColumnSelectedItems: function (rule_type, col_ind, opt) {
      if (opt) {
        if (!this.d_project.column_list[col_ind].selected_items) {
          this.d_project.column_list[col_ind].selected_items = [];
        }
        let det = false;
        for (let i in this.d_project.column_list[col_ind].selected_items) {
          if (this.d_project.column_list[col_ind].selected_items[i].value === opt.value) {
            det = true;
            break;
          }
        }
        if (!det) {
          this.d_project.column_list[col_ind].selected_items.push(opt);
        }
      } else {
        this.d_project.column_list[col_ind].selected_items.splice(0, this.d_project.column_list[col_ind].selected_items.length);
      }
      if (this.d_autoLoading) {
        this.f_wanalyzerServicesTrigger();
      }
    },
    f_wanalyzerProjectStatsResultList: function () {
      this.d_statsList.analyze_list = [];
      let query = 'project_id=' + this.d_projectId ;
      query += '&stats_id=' + this.d_statsId ;
      let data = {
        'selected_columns': this.d_filterData.selected_columns,
        'selected_stats': this.d_filterData.selected_stats,
        'selected_p_value': this.d_filterData.selected_p_value,
        'selected_analyze_types': this.d_filterData.selected_analyze_types,
        'user_score_list': this.d_filterData.user_score_list,
        'pagination': this.d_filterData.pagination,
      };
      ServiceFunctions.wanalyzer_project_stats_result_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_statsList.analyze_list = resp.data.analyze_list;
            this.d_statsList.all_count = resp.data.all_count;
            this.d_statsList.filter_stats = resp.data.filter_stats;
            this.d_statsList.filter_analyze_types = resp.data.filter_analyze_types;
            this.d_statsList.selected_columns = resp.data.selected_columns;
            this.d_statsList.stats_name = resp.data.stats_name;
            this.d_statsList.stats_info = resp.data.stats_info;
            this.d_statsList.last_update_time_of_project_cases = resp.data.last_update_time_of_project_cases;
            this.d_filterData.pagination.all_count = resp.data.all_count;
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_calculateChartWidth: function (chart) {
      if (chart.labels) {
        if (chart.labels.length > 5 && chart.labels.length <= 10) {
          return 800;
        } else if (chart.labels.length > 10) {
          return 1000;
        } else {
          return 500;
        }
      } else {
        return 500;
      }
    },
    f_showChartData: function (chart_data) {
      let new_chart_data = {
        backgroundColor: "#fff",
        labels: chart_data.labels ? chart_data.labels : [],
        datasets: []
      };
      for (let d in chart_data.datasets) {
        let new_dataset = {}
        if (chart_data.datasets[d].label !== undefined) {
          new_dataset.label = chart_data.datasets[d].label;
        }
        if (chart_data.datasets[d].lineTension !== undefined) {
          new_dataset.lineTension = chart_data.datasets[d].lineTension;
        }
        if (chart_data.datasets[d].borderColor !== undefined) {
          new_dataset.borderColor = chart_data.datasets[d].borderColor;
        }
        if (chart_data.datasets[d].backgroundColor !== undefined) {
          new_dataset.backgroundColor = chart_data.datasets[d].backgroundColor;
        }
        if (chart_data.datasets[d].showLine !== undefined) {
          new_dataset.showLine = chart_data.datasets[d].showLine;
        }
        if (chart_data.datasets[d].data !== undefined) {
          new_dataset.data = chart_data.datasets[d].data;
        }
        new_chart_data.datasets.push(new_dataset);
      }
      this.chart_data = JSON.parse(JSON.stringify(new_chart_data));
      this.d_resetChartData = JSON.parse(JSON.stringify(this.chart_data));
      this.d_chartSize.chart_type = chart_data.type;
      this.d_chartSize.x_mode = 'standart';
      this.d_chartSize.y_mode = 'standart';
      this.d_chartSize.x_interval_type = 'all';
      this.d_chartSize.height = window.innerHeight - 200;
      this.d_chartSize.width = window.innerWidth - 100;
      this.d_showGraphicView = true;
    },
    f_calculateChartHeight: function (chart) {
      if (chart.labels) {
        if (chart.labels.length > 5 && chart.labels.length <= 10) {
          return 400;
        } else if (chart.labels.length > 10) {
          return 500;
        } else {
          return 250;
        }
      } else {
        return 250;
      }
    },
    f_calculateChartStyle: function (chart) {
      let style = '';
      if (chart.labels) {
        if (chart.labels.length > 5 && chart.labels.length <= 10) {
          if (chart.datasets.length > 5 && chart.datasets.length <= 10) {
            return 'position: relative; height:600px; width:1200px;';
          } else if (chart.datasets.length > 10) {
            return 'position: relative; height:800px; width:1600px;';
          } else {
            return 'position: relative; height:400px; width:800px;';
          }
        } else if (chart.labels.length > 10) {
          if (chart.datasets.length > 5 && chart.datasets.length <= 10) {
            return 'position: relative; height:700px; width:1400px;';
          } else if (chart.datasets.length > 10) {
            return 'position: relative; height:900px; width:1800px;';
          } else {
            return 'position: relative; height:500px; width:1000px;';
          }
        } else {
          if (chart.datasets.length > 5 && chart.datasets.length <= 10) {
            return 'position: relative; height:450px; width:900px;';
          } else if (chart.datasets.length > 10) {
            return 'position: relative; height:650px; width:1300px;';
          } else {
            return 'position: relative; height:250px; width:500px;';
          }
        }
      } else {
        return 'position: relative; height:250px; width:500px;';
      }
    },
    f_calculateTableTdStyle: function (r_index, c_index) {
      let style = 'min-width: 100px; text-align: center; border: solid 1px #c3d8d8; font-size: 12px;';
      if (r_index === 0 || c_index === 0) {
        style += ' font-weight: bold; font-size: 10px;';
      }
      /*
        if (r_index === 0) {
          style += 'position: sticky; z-index: 1000; top: 0; background-color: #00bcd4;';
        }
        if (c_index === 0) {
          style += 'position: sticky; left: 0;';
        }
      */
      return style;
    },
    f_tableShow: function () {
      this.d_tableShow = false;
      setTimeout(function () {
        this.d_tableShow = true;
        this.$forceUpdate();
      }.bind(this), 1000);
    },
    f_calculateTableStyle: function () {
      let style = '';
      if (this.d_fullScreen) {
        style = 'overflow-x: auto;';
      } else {
        style = 'overflow-x: auto;';
      }
      return style;
    },
    f_searchCrossRows: function (row, row_index, rows_length) {
      // if (this.d_searchTextRow && row_index !== 0 && row_index !== rows_length - 1) {
      if (this.d_searchTextRow && row_index !== 0) {
        let text_list = this.d_searchTextRow.split(',');
        for (let i in text_list) {
          text_list[i] = text_list[i].replace(/ /g, "");
        }
        for (let t in text_list) {
          if (text_list[t]) {
            if (row[0].toLocaleLowerCase().indexOf(text_list[t].toLocaleLowerCase()) !== -1) {
              return true;
            }
          }
        }
      } else {
        if (row_index >= this.d_tablePagination.start && row_index <= this.d_tablePagination.end) {
          return true;
        }
      }
      return false;
    },
    f_searchCrossColumns: function (col_index, first_row, col_length) {
      // if (this.d_searchTextColumn && col_index !== 0 && col_index !== col_length - 1) {
      if (this.d_searchTextColumn && col_index !== 0) {
        let text_list = this.d_searchTextColumn.split(',');
        for (let i in text_list) {
          text_list[i] = text_list[i].replace(/ /g, "");
        }
        for (let t in text_list) {
          if (text_list[t]) {
            if (first_row[col_index].toLocaleLowerCase().indexOf(text_list[t].toLocaleLowerCase()) !== -1) {
              return true;
            }
          }
        }
        return false;
      } else {
        return true;
      }
    },
    f_dateFormat: function (date, date_type='year-month-day') {
      if (date) {
        if (date_type === 'year-month-day') {
          if (this.StoreLang === 'tr') {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");
            return x;
          } else {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("YYYY/MM/DD");
            return x;
          }
        } else if (date_type === 'year-month-day hh:mm') {
          if (this.StoreLang === 'tr') {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm");
            return x;
          } else {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("YYYY/MM/DD hh:mm");
            return x;
          }
        }
      } else {
        return "";
      }
    },
    f_controlWaiOutputMode: function (col, output_show_type) {
      let show_outpul_col = false;
      if (col.wai_output === 1) {
        if (output_show_type === 'right') {
          if (col.style === undefined || (col.style && (col.style.waiLocation === undefined || col.style.waiLocation === 'right_any_location'))) {
            show_outpul_col = true;
          }
        } else if (output_show_type === 'bottom') {
          if (col.style && col.style.waiLocation === 'bottom_as_card') {
            show_outpul_col = true;
          }
        }
      }
      return show_outpul_col;
    },
    f_showTextAsModal: function (text) {
      this.d_selectedTableCellFullText = text.toString();
      this.d_showTableCellFullText = true;
    },
    f_calculateColumnStyle: function (column_data) {
      let column_style = 'width: 100%; padding: 3px;';
      // let column_style = 'width: 100%; padding: 3px; text-align: center;';
      // console.log('column_data.style : ', column_data.style);
      if (column_data.style && column_data.style.backgroundColor) {
        column_style += 'background: ' + column_data.style.backgroundColor + ';';
      }
      if (column_data.style && column_data.style.color) {
        column_style += 'color: ' + column_data.style.color + ';';
      }
      if (column_data.style && column_data.style.fontSize) {
        column_style += 'font-size: ' + column_data.style.fontSize + 'px;';
      }
      return column_style;
    },
    f_resetForm: function () {
      this.d_clientId = '';
      this.d_wdmrId = '';
      this.d_project.details = '';
      this.d_project.column_list = [];
      this.f_wanalyzerProjectStatsResultList();
    },
    f_selectColumnUseAsStats: function (col_index) {
      if (this.d_project.column_list[col_index].use_as_stats === 'yes') {
        this.d_project.column_list[col_index].use_as_stats = 'no';
      } else {
        this.d_project.column_list[col_index].use_as_stats = 'yes';
      }
      this.$forceUpdate();
    },
    f_wanalyzerProjectStatisticsAnalyzeList: function () {
      if (!this.d_statsViewLoading) {
        // this.d_statisticResults = {};
        let is_there_eligible_column_to_analyze = false;
        let stats_auto_1 = this.f_prepareStatsData('cross_columns', 'auto_1', '')
        let stats_data = {
          'rule_algoritm': '',
          'data': {
            'general': {
              'analyze_type': {'val': {'value': 'detailed_descriptive_stats'}},
              'column_selection_type': {'val': {'value': 'manuel_column_selection'}},
              'selected_columns': {},
              'descriptive_stats': {
                'mean': {'name': {'value': 'mean'}, 'detail': {}},
                'frequency': {'name': {'value': 'frequency'}, 'detail': {}},
              }
            }
          }
        };
        // console.log('this.p_selectedProjectType : ', this.p_selectedProjectType);
        for (let i in this.d_project.column_list) {
          // console.log('this.d_project.column_list[i].param_type ', this.d_project.column_list[i].param_type);
          // console.log('this.d_project.column_list[i].wai_output ', this.d_project.column_list[i].wai_output);
          if (['select', 'integer', 'float', 'radiogroup'].indexOf(this.d_project.column_list[i].param_type) !== -1 || this.d_project.column_list[i].stats_data_type) {
            let use_column = false;
            if (['wanalyzer_create_query'].indexOf(this.p_selectedProjectType) !== -1) {
              use_column = true;
            } else if (['wanalyzer_dataset_stats_query', 'wanalyzer_dataset_filter'].indexOf(this.p_selectedProjectType) !== -1) {
              if (this.d_project.column_list[i].wai_output) {
                use_column = true;
              }
            }
            // console.log('use_column ', use_column);
            // console.log('this.d_project.column_list[i].use_as_stats ', this.d_project.column_list[i].use_as_stats);
            if (use_column) {
              if (this.d_project.column_list[i].use_as_stats === 'yes') {
                is_there_eligible_column_to_analyze = true;
                if (this.d_project.column_list[i].stats_data_type == 'categorical') {
                  stats_data['data']['general']['selected_columns'][this.d_project.column_list[i].index] = {
                    'name': {'value': this.d_project.column_list[i].index, 'label': this.d_project.column_list[i].name},
                    'detail': {
                        "param_type_usage": {"val": {"value": "selected"} },
                        "column_param_type": {"val": {"value": "categorical"} },
                        "option_group_type": {"val": {"value": "standard_mode"} },
                        "selected_column_timeline_mode": {"val": {"value": "no"} }
                    }
                  }

                  stats_auto_1['data']['general']['selected_columns'][this.d_project.column_list[i].index] = {
                    'name': {'value': this.d_project.column_list[i].index, 'label': this.d_project.column_list[i].name},
                    'detail': {
                        "param_type_usage": {"val": {"value": "selected"} },
                        "column_param_type": {"val": {"value": "categorical"} },
                        "option_group_type": {"val": {"value": "standard_mode"} },
                        "selected_column_timeline_mode": {"val": {"value": "no"} }
                    }
                  }
                } else if (this.d_project.column_list[i].stats_data_type == 'numeric') {
                  stats_data['data']['general']['selected_columns'][this.d_project.column_list[i].index] = {
                    'name': {'value': this.d_project.column_list[i].index, 'label': this.d_project.column_list[i].name},
                    'detail': {
                        "param_type_usage": {"val": {"value": "selected"} },
                        "column_param_type": {"val": {"value": "numeric"} },
                        "option_group_type": {"val": {"value": "standard_mode"} },
                        "selected_column_timeline_mode": {"val": {"value": "no"} }
                    }
                  }

                  stats_auto_1['data']['general']['selected_columns'][this.d_project.column_list[i].index] = {
                    'name': {'value': this.d_project.column_list[i].index, 'label': this.d_project.column_list[i].name},
                    'detail': {
                        "param_type_usage": {"val": {"value": "selected"} },
                        "column_param_type": {"val": {"value": "numeric"} },
                        "option_group_type": {"val": {"value": "standard_mode"} },
                        "selected_column_timeline_mode": {"val": {"value": "no"} }
                    }
                  }
                } else {
                  stats_data['data']['general']['selected_columns'][this.d_project.column_list[i].index] = {
                    'name': {'value': this.d_project.column_list[i].index, 'label': this.d_project.column_list[i].name},
                    'detail': {
                        "param_type_usage": {"val": {"value": "auto"} },
                        "option_group_type": {"val": {"value": "standard_mode"} },
                        "selected_column_timeline_mode": {"val": {"value": "no"} }
                    }
                  }

                  stats_auto_1['data']['general']['selected_columns'][this.d_project.column_list[i].index] = {
                    'name': {'value': this.d_project.column_list[i].index, 'label': this.d_project.column_list[i].name},
                    'detail': {
                        "param_type_usage": {"val": {"value": "auto"} },
                        "option_group_type": {"val": {"value": "standard_mode"} },
                        "selected_column_timeline_mode": {"val": {"value": "no"} }
                    }
                  }
                }
                
              }

            }
          }
        }
        // console.log('is_there_eligible_column_to_analyze : ', is_there_eligible_column_to_analyze);
        let rule_algo = this.f_createRuleAlgorithm(this.d_project);
        if (is_there_eligible_column_to_analyze) {
          stats_data.rule_algoritm = rule_algo;
          stats_auto_1.rule_algoritm = rule_algo;
          let query = 'project_id=' + this.d_projectId;
          if (this.p_projectPermission) {
            query += '&auth_client_id=' + this.p_projectPermission.auth_data.client_id;
          }
          let data = {
            'list': []
          };
          data.list.push(stats_data);
          data.list.push(stats_auto_1);
          // console.log(data);
          this.d_statsViewLoading = true;
          this.d_statisticResults = {};
          ServiceFunctions.wanalyzer_project_statistics_analyze_list(query, data)
            .then(resp => {
              this.d_statsViewLoading = false;
              if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
                this.d_statisticResults = resp.data.statistic_results;
                for (let i in this.d_statisticResults) {
                  for (let k in this.d_statisticResults[i]) {
                    for (let a in this.d_statisticResults[i][k].analyze_list) {
                      this.d_selectedTabItemId = 'analyze_' + i + '_' + k + '_' + a;
                      break
                    }
                    break
                  }
                  break
                }
                this.f_statsShowRefresh();
              } else {
                let txt = 'status_code = ' + resp.data.status_code + '\n';
                txt += 'status_message = ' + resp.data.status_message;
                alert(txt);
              }
            });
        }
      }
    },
    f_prepareStatsData: function (type, easy_stats_type = '', column_selection_type = '') {
      /*
        @click="f_prepareStatsData('mean')"
        @click="f_prepareStatsData('median')"
        @click="f_prepareStatsData('frequency')"
        @click="f_prepareStatsData('cross_columns')"
        @click="f_prepareStatsData('detailed_descriptive_stats','i_will_select_columns')"
        @click="f_prepareStatsData('detailed_descriptive_stats','numeric_columns')"
        @click="f_prepareStatsData('detailed_descriptive_stats','categorical_columns')"
        @click="f_prepareStatsData('detailed_descriptive_stats','all_columns')"
        @click="f_prepareStatsData('detailed_descriptive_stats','i_will_select_columns_test_group_1')"
        @click="f_prepareStatsData('detailed_descriptive_stats','all_columns_test_group_1')"
        @click="f_prepareStatsData('sum')"
        @click="f_prepareStatsData('before_after_comparison')"
        @click="f_prepareStatsData('frequency')"
        @click="f_prepareStatsData('mean')"
        @click="f_prepareStatsData('median')"
        @click="f_prepareStatsData('max')"
        @click="f_prepareStatsData('min')"
        @click="f_prepareStatsData('standart_deviation')"
        @click="f_prepareStatsData('outliers_zindex')"
        @click="f_prepareStatsData('outliers_interquartile_range')"
        @click="f_prepareStatsData('skewness')"
        @click="f_prepareStatsData('kurtosis')"
        @click="f_prepareStatsData('kolmogorov_smirnov')"
        @click="f_prepareStatsData('shapiro_wilk')"
        @click="f_prepareStatsData('cross_columns', 'categorical_categorical_descriptive')"
        @click="f_prepareStatsData('cross_columns', 'categorical_categorical_chisquare')"
        @click="f_prepareStatsData('cross_columns', 'categorical_categorical_fisherexact')"
        @click="f_prepareStatsData('cross_columns', 'categorical_categorical_chisquare_fisherexact')"
        @click="f_prepareStatsData('cross_columns', 'categorical_numeric_descriptive')"
        @click="f_prepareStatsData('cross_columns', 'categorical_numeric_t_test')"
        @click="f_prepareStatsData('cross_columns', 'categorical_numeric_mann_whitney_u')"
        @click="f_prepareStatsData('cross_columns', 'categorical_numeric_oneway_anova')"
        @click="f_prepareStatsData('cross_columns', 'categorical_numeric_kruskal_wallis')"
        @click="f_prepareStatsData('cross_columns', 'numeric_numeric_pearson_corelation')"
        @click="f_prepareStatsData('cross_columns', 'numeric_numeric_spearman')"
        @click="f_prepareStatsData('before_after_comparison', 'numeric_numeric_pairs_sample_t_test')"
        @click="f_prepareStatsData('cross_columns', 'numeric_categorical_logistic_regression')"
        @click="f_prepareStatsData('cross_columns', 'xxxxxxxxxxx')"
        @click="f_prepareStatsData('cross_columns', 'auto_1')"
      */
      let pre_data = {
        // 'id': 'new',
        'data': {
          'general': {
            'selected_columns': {}
          }
        },
        // 'type': 'wdm72',
        // 'reference_wdm': {},
        // 'wdm3_id': this.p_selectedProjectData.id
      };
      // pre_data['reference_wdm']['version'] = this.d_statsWdm['version'];
      // pre_data['reference_wdm']['owner_type'] = this.d_statsWdm['owner_type'];
      // if (this.d_statsWdm['owner_type'] === 'wisdom') {
      //   pre_data['reference_wdm']['key'] = 'wdm_wisdom_wdm72_v' + this.d_statsWdm.version;
      // }
      // for (let i in this.d_statsWdm.param_group.param_order) {
      //   pre_data['data'][this.d_statsWdm.param_group.param_order[i]] = {};
      // }
      let analyze_type = '';
      for (let i in this.d_statsWdm.general.parameters.analyze_type.options) {
        if (this.d_statsWdm.general.parameters.analyze_type.options[i].value === type) {
          analyze_type = this.d_statsWdm.general.parameters.analyze_type.options[i];
          break;
        }
      }
      // pre_data['data']['general']['wdm3_name'] = { 'val': this.p_selectedProjectData.data.general.name.val };
      // pre_data['data']['general']['wdm3_key'] = { 'val': 'wdm3_' + this.p_selectedProjectData.id };
      pre_data['data']['general']['analyze_type'] = { 'val': analyze_type };
      pre_data['data']['general']['analyze_time_type'] = { 'val': { 'value': 'manuel', 'label': 'manuel' } };
      pre_data['data']['general']['auto_combination_cross_analysis'] = { 'val': { 'value': 'no', 'label': 'hayır' } };
      // categorical & categorical
      if (type === 'cross_columns') {
        if (easy_stats_type === 'auto_1') {
          pre_data['data']['general']['model_name'] = { 'val': 'Comperative analysis' };
          pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
          pre_data['data']['general']['selected_categorical_to_nominal'] = {};
          pre_data['data']['general']['selected_categorical_to_categorical'] = {};
          pre_data['data']['general']['selected_nominal_to_nominal'] = {};
          pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'auto', 'label': 'otomatik' } };
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'manuel_column_selection', 'label': 'manuel kolon seçimi' } };
          pre_data['data']['general']['auto_combination_cross_analysis'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
          pre_data['data']['general']['sample_count_control'] = { 'val': { 'value': 'no', 'label': 'hayır' } };
          pre_data['data']['general']['group_count_control'] = { 'val': { 'value': 'no', 'label': 'hayır' } };
          // numeric to numeric
          pre_data['data']['general']['selected_nominal_to_nominal']['pearson_correlation'] = {
            'name': { 'value': 'pearson_correlation', 'label': 'pearson korelasyon' },
            'detail': {
              'numeric_numeric_control_type_before_test': { 'val': { 'value': 'only_total_samples_data_distribution', 'label': 'sadece tüm örneklere ait veri dağılımı' } },
              'numeric_numeric_distribution_test_list_usage_type': { 'val': { 'value': 'all_of_them', 'label': 'tüm seçili testler normal dağılıyorsa kullan' } },
              'numeric_numeric_distribution_skewness': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'numeric_numeric_distribution_skewness_range': { 'val': '1.5' },
              'numeric_numeric_distribution_kurtosis': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'numeric_numeric_distribution_kurtosis_range': { 'val': '1.5' },
              'numeric_numeric_distribution_shapiro_wilk': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'numeric_numeric_distribution_kolmogorov_smirnov': { 'val': { 'value': 'no', 'label': 'hayır' } },
            }
          };
          pre_data['data']['general']['selected_nominal_to_nominal']['spearman'] = {
            'name': { 'value': 'spearman', 'label': 'spearman' },
            'detail': {
              'numeric_numeric_control_type_before_test': { 'val': { 'value': 'only_total_samples_data_distribution', 'label': 'sadece tüm örneklere ait veri dağılımı' } },
              'numeric_numeric_distribution_test_list_usage_type': { 'val': { 'value': 'one_of_them_not_normally_distributed', 'label': 'testlerden birisi normal dağılmıyorsa kullan' } },
              'numeric_numeric_distribution_skewness': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'numeric_numeric_distribution_skewness_range': { 'val': '1.5' },
              'numeric_numeric_distribution_kurtosis': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'numeric_numeric_distribution_kurtosis_range': { 'val': '1.5' },
              'numeric_numeric_distribution_shapiro_wilk': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'numeric_numeric_distribution_kolmogorov_smirnov': { 'val': { 'value': 'no', 'label': 'hayır' } },
            }
          };
          // categorical to categorical
          pre_data['data']['general']['selected_categorical_to_categorical']['fisherexact'] = { 'name': { 'value': 'fisherexact', 'label': 'Fisher Exact' }, 'detail': {} };
          pre_data['data']['general']['selected_categorical_to_categorical']['chisquare'] = { 'name': { 'value': 'chisquare', 'label': 'Chi square' }, 'detail': {} };
          // categorical to numeric
          pre_data['data']['general']['selected_categorical_to_nominal']['oneway_anova'] = {
            'name': { 'value': 'oneway_anova', 'label': 'Oneway ANOVA' },
            'detail': {
              'categorical_numeric_control_type_before_test': { 'val': { 'value': 'only_every_group_data_distribution', 'label': 'sadece her bir gruba ait veri dağılımı' } },
              'categorical_numeric_distribution_test_list_usage_type': { 'val': { 'value': 'all_of_them', 'label': 'tüm seçili testler normal dağılıyorsa kullan' } },
              'categorical_numeric_distribution_skewness': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_skewness_range': { 'val': '1.5' },
              'categorical_numeric_distribution_kurtosis': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_kurtosis_range': { 'val': '1.5' },
              'categorical_numeric_distribution_shapiro_wilk': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_kolmogorov_smirnov': { 'val': { 'value': 'no', 'label': 'hayır' } },
            }
          };
          pre_data['data']['general']['selected_categorical_to_nominal']['kruskal_wallis'] = {
            'name': { 'value': 'kruskal_wallis', 'label': 'Kruskal Wallis' },
            'detail': {
              'categorical_numeric_control_type_before_test': { 'val': { 'value': 'only_every_group_data_distribution', 'label': 'sadece her bir gruba ait veri dağılımı' } },
              'categorical_numeric_distribution_test_list_usage_type': { 'val': { 'value': 'one_of_them_not_normally_distributed', 'label': 'testlerden birisi normal dağılmıyorsa kullan' } },
              'categorical_numeric_distribution_skewness': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_skewness_range': { 'val': '1.5' },
              'categorical_numeric_distribution_kurtosis': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_kurtosis_range': { 'val': '1.5' },
              'categorical_numeric_distribution_shapiro_wilk': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_kolmogorov_smirnov': { 'val': { 'value': 'no', 'label': 'hayır' } },
            }
          };
          pre_data['data']['general']['selected_categorical_to_nominal']['mann_whitney_u'] = {
            'name': { 'value': 'mann_whitney_u', 'label': 'Mann Whitney U' },
            'detail': {
              'categorical_numeric_control_type_before_test': { 'val': { 'value': 'only_every_group_data_distribution', 'label': 'sadece her bir gruba ait veri dağılımı' } },
              'categorical_numeric_distribution_test_list_usage_type': { 'val': { 'value': 'all_of_them_not_normally_distributed', 'label': 'tüm seçili testler normal dağılmıyorsa kullan' } },
              'categorical_numeric_distribution_skewness': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_skewness_range': { 'val': '1.5' },
              'categorical_numeric_distribution_kurtosis': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_kurtosis_range': { 'val': '1.5' },
              'categorical_numeric_distribution_shapiro_wilk': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_kolmogorov_smirnov': { 'val': { 'value': 'no', 'label': 'hayır' } },
            }
          };
          pre_data['data']['general']['selected_categorical_to_nominal']['t_test'] = {
            'name': { 'value': 't_test', 'label': 't test' },
            'detail': {
              'categorical_numeric_control_type_before_test': { 'val': { 'value': 'only_every_group_data_distribution', 'label': 'sadece her bir gruba ait veri dağılımı' } },
              'categorical_numeric_distribution_test_list_usage_type': { 'val': { 'value': 'all_of_them_not_normally_distributed', 'label': 'tüm seçili testler normal dağılmıyorsa kullan' } },
              'categorical_numeric_distribution_skewness': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_skewness_range': { 'val': '1.5' },
              'categorical_numeric_distribution_kurtosis': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_kurtosis_range': { 'val': '1.5' },
              'categorical_numeric_distribution_shapiro_wilk': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_kolmogorov_smirnov': { 'val': { 'value': 'no', 'label': 'hayır' } },
            }
          };
        }
      }
      if (easy_stats_type === 'categorical_categorical_descriptive') {
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_categorical', 'label': 'kategorik & kategorik' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'no', 'label': 'hayır' } };
      } else if (easy_stats_type === 'categorical_categorical_fisherexact') {
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_categorical', 'label': 'kategorik & kategorik' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_categorical_to_categorical'] = {};
        pre_data['data']['general']['selected_categorical_to_categorical']['fisherexact'] = { 'name': { 'value': 'fisherexact', 'label': 'Fisher Exact' }, 'detail': {} };
      } else if (easy_stats_type === 'categorical_categorical_chisquare') {
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_categorical', 'label': 'kategorik & kategorik' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_categorical_to_categorical'] = {};
        pre_data['data']['general']['selected_categorical_to_categorical']['chisquare'] = { 'name': { 'value': 'chisquare', 'label': 'Chi square' }, 'detail': {} };
      } else if (easy_stats_type === 'categorical_categorical_chisquare_fisherexact') {
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_categorical', 'label': 'kategorik & kategorik' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_categorical_to_categorical'] = {};
        pre_data['data']['general']['selected_categorical_to_categorical']['fisherexact'] = { 'name': { 'value': 'fisherexact', 'label': 'Fisher Exact' }, 'detail': {} };
        pre_data['data']['general']['selected_categorical_to_categorical']['chisquare'] = { 'name': { 'value': 'chisquare', 'label': 'Chi square' }, 'detail': {} };
      }
      // categorical & numeric
      if (easy_stats_type === 'categorical_numeric_descriptive') {
        pre_data['data']['general']['model_name'] = { 'val': 'Kategorik verilerle sayısal verilerin karşılaştırılması' };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_numeric', 'label': 'kategorik & sayısal' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'no', 'label': 'hayır' } };
      } else if (easy_stats_type === 'categorical_numeric_t_test') {
        pre_data['data']['general']['model_name'] = { 'val': 'Kategorik verilerle sayısal verilerin karşılaştırılması' };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_numeric', 'label': 'kategorik & sayısal' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_categorical_to_nominal'] = {};
        pre_data['data']['general']['selected_categorical_to_nominal']['t_test'] = { 'name': { 'value': 't_test', 'label': 't test' }, 'detail': {} };
      } else if (easy_stats_type === 'categorical_numeric_kruskal_wallis') {
        pre_data['data']['general']['model_name'] = { 'val': 'Kategorik verilerle sayısal verilerin karşılaştırılması' };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_numeric', 'label': 'kategorik & sayısal' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_categorical_to_nominal'] = {};
        pre_data['data']['general']['selected_categorical_to_nominal']['kruskal_wallis'] = { 'name': { 'value': 'kruskal_wallis', 'label': 'Kruskal Wallis' }, 'detail': {} };
      } else if (easy_stats_type === 'categorical_numeric_mann_whitney_u') {
        pre_data['data']['general']['model_name'] = { 'val': 'Kategorik verilerle sayısal verilerin karşılaştırılması' };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_numeric', 'label': 'kategorik & sayısal' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_categorical_to_nominal'] = {};
        pre_data['data']['general']['selected_categorical_to_nominal']['mann_whitney_u'] = { 'name': { 'value': 'mann_whitney_u', 'label': 'Mann Whitney U test' }, 'detail': {} };
      } else if (easy_stats_type === 'categorical_numeric_oneway_anova') {
        pre_data['data']['general']['model_name'] = { 'val': 'Kategorik verilerle sayısal verilerin karşılaştırılması' };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_numeric', 'label': 'kategorik & sayısal' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_categorical_to_nominal'] = {};
        pre_data['data']['general']['selected_categorical_to_nominal']['oneway_anova'] = { 'name': { 'value': 'oneway_anova', 'label': 'Oneway ANOVA' }, 'detail': {} };
      }
      // numeric & numeric
      if (easy_stats_type === 'numeric_numeric_pearson_corelation') {
        pre_data['data']['general']['model_name'] = { 'val': 'Sayısal verilerle sayısal verilerin karşılaştırılarak korelasyon değerlendirilmesi' };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'numeric_numeric', 'label': 'sayısal & sayısal' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_nominal_to_nominal'] = {};
        pre_data['data']['general']['selected_nominal_to_nominal']['pearson_correlation'] = { 'name': { 'value': 'pearson_correlation', 'label': 'pearson korelasyon' }, 'detail': {} };
      } else if (easy_stats_type === 'numeric_numeric_spearman') {
        pre_data['data']['general']['model_name'] = { 'val': 'Sayısal verilerle sayısal verilerin karşılaştırılarak korelasyon değerlendirilmesi' };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'numeric_numeric', 'label': 'sayısal & sayısal' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_nominal_to_nominal'] = {};
        pre_data['data']['general']['selected_nominal_to_nominal']['spearman'] = { 'name': { 'value': 'spearman', 'label': 'spearman' }, 'detail': {} };
      } else if (easy_stats_type === 'numeric_numeric_pairs_sample_t_test') {
        pre_data['data']['general']['model_name'] = { 'val': 'Sayısal verinin önce -> sonra değişim analizi' };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'numeric_numeric', 'label': 'sayısal & sayısal' } };
        pre_data['data']['general']['before_after'] = {
          'list': [{
            'selected_before_after_statistical_test': {
              'ttest_relation_paired_samples': { 'name': { 'value': 'ttest_relation_paired_samples', 'label': 'bağımlı gruplar t testi (paired samples)' }, 'detail': {} }
            }
          }]
        };
      }
      // numeric & categorical
      if (easy_stats_type === 'numeric_categorical_logistic_regression') {
        pre_data['data']['general']['model_name'] = { 'val': 'Verilerle 2 kategorik grup arasındaki ilişkinin logistic regresyon ile değerlendirilmesi.' };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'no', 'label': 'hayır' } };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'numeric_categorical', 'label': 'sayısal & categorical' } };
        pre_data['data']['general']['one_or_multi_independent_one_dependent_categorical_selected'] = {};
        pre_data['data']['general']['one_or_multi_independent_one_dependent_categorical_selected']['logistic_regression'] = {
          'name': { 'value': 'logistic_regression', 'label': 'Lojistik Regresyon' },
          'detail': {
            'oomiodc_variable_selection': {
              'val': [{ 'value': '1', 'label': 'tüm değişkenleri hedef değişken ile toplu analiz yap' }]
            }
          }
        };
      }
      if (type === 'detailed_descriptive_stats') {
        pre_data['data']['general']['model_name'] = { 'val': 'Detaylı Tanımlayıcı İstatistikler' };
        if (easy_stats_type === 'i_will_select_columns') {
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'manuel_column_selection', 'label': 'manuel kolon seçimi' } };
          pre_data['data']['general']['descriptive_stats'] = {
            'mean': { 'name': { 'value': 'mean', 'label': 'ortalama' }, 'detail': {} },
            'median': { 'name': { 'value': 'median', 'label': 'ortanca (medyan)' }, 'detail': {} },
            'standart_deviation': { 'name': { 'value': 'standart_deviation', 'label': 'standart sapma' }, 'detail': {} },
            'variance': { 'name': { 'value': 'variance', 'label': 'varyans' }, 'detail': {} },
            'min': { 'name': { 'value': 'min', 'label': 'minimum' }, 'detail': {} },
            'max': { 'name': { 'value': 'max', 'label': 'maksimum' }, 'detail': {} },
            'kurtosis': { 'name': { 'value': 'kurtosis', 'label': 'veri dağılımı (basıklık, kurtosis)' }, 'detail': {} },
            'skewness': { 'name': { 'value': 'skewness', 'label': 'veri dağılımı (eğim, skewness)' }, 'detail': {} },
            'frequency': { 'name': { 'value': 'frequency', 'label': 'sıklık' }, 'detail': {} },
            'kolmogorov_smirnov': { 'name': { 'value': 'kolmogorov_smirnov', 'label': 'veri dağılımı (kolmogrov smirnov)' }, 'detail': {} },
            'shapiro_wilk': { 'name': { 'value': 'shapiro_wilk', 'label': 'shapiro wilk' }, 'detail': {} },
            'outliers_zindex': { 'name': { 'value': 'outliers_zindex', 'label': 'aykırı değer saptama (outliers) z-index' }, 'detail': {} }
          };
        } else if (easy_stats_type === 'i_will_select_columns_test_group_1') {
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'manuel_column_selection', 'label': 'manuel kolon seçimi' } };
          pre_data['data']['general']['descriptive_stats'] = {
            'mean': { 'name': { 'value': 'mean', 'label': 'ortalama' }, 'detail': {} },
            'median': { 'name': { 'value': 'median', 'label': 'ortanca (medyan)' }, 'detail': {} },
            'standart_deviation': { 'name': { 'value': 'standart_deviation', 'label': 'standart sapma' }, 'detail': {} },
            'min': { 'name': { 'value': 'min', 'label': 'minimum' }, 'detail': {} },
            'max': { 'name': { 'value': 'max', 'label': 'maksimum' }, 'detail': {} },
            'kurtosis': { 'name': { 'value': 'kurtosis', 'label': 'veri dağılımı (basıklık, kurtosis)' }, 'detail': {} },
            'skewness': { 'name': { 'value': 'skewness', 'label': 'veri dağılımı (eğim, skewness)' }, 'detail': {} },
            'frequency': { 'name': { 'value': 'frequency', 'label': 'sıklık' }, 'detail': {} },
            'shapiro_wilk': { 'name': { 'value': 'shapiro_wilk', 'label': 'shapiro wilk' }, 'detail': {} }
          };
        } else if (easy_stats_type === 'numeric_columns') {
          // pre_data['data']['general']['selected_columns'] = this.f_addColumnsByParamTypes(['integer', 'float']);
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'all_numeric_columns', 'label': 'tüm numeric kolonlar' } };
          pre_data['data']['general']['descriptive_stats'] = {
            'mean': { 'name': { 'value': 'mean', 'label': 'ortalama' }, 'detail': {} },
            'median': { 'name': { 'value': 'median', 'label': 'ortanca (medyan)' }, 'detail': {} },
            'standart_deviation': { 'name': { 'value': 'standart_deviation', 'label': 'standart sapma' }, 'detail': {} },
            'variance': { 'name': { 'value': 'variance', 'label': 'varyans' }, 'detail': {} },
            'min': { 'name': { 'value': 'min', 'label': 'minimum' }, 'detail': {} },
            'max': { 'name': { 'value': 'max', 'label': 'maksimum' }, 'detail': {} },
            'kurtosis': { 'name': { 'value': 'kurtosis', 'label': 'veri dağılımı (basıklık, kurtosis)' }, 'detail': {} },
            'skewness': { 'name': { 'value': 'skewness', 'label': 'veri dağılımı (eğim, skewness)' }, 'detail': {} },
            'kolmogorov_smirnov': { 'name': { 'value': 'kolmogorov_smirnov', 'label': 'veri dağılımı (kolmogrov smirnov)' }, 'detail': {} },
            'shapiro_wilk': { 'name': { 'value': 'shapiro_wilk', 'label': 'veri dağılımı (shapiro_wilk)' }, 'detail': {} },
            'outliers_zindex': { 'name': { 'value': 'outliers_zindex', 'label': 'aykırı değer saptama (outliers) z-index' }, 'detail': {} }
          };

        } else if (easy_stats_type === 'categorical_columns') {
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'all_categorical_columns', 'label': 'tüm kategorik kolonlar' } };
          // pre_data['data']['general']['selected_columns'] = this.f_addColumnsByParamTypes(['select', 'radiogroup', 'object_list_options', 'checkbox']);
          pre_data['data']['general']['descriptive_stats'] = {
            'frequency': { 'name': { 'value': 'frequency', 'label': 'sıklık' }, 'detail': {} }
          };
        } else if (easy_stats_type === 'all_columns') {
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'all_columns', 'label': 'tüm kolonlar' } };
          // pre_data['data']['general']['selected_columns'] = this.f_addColumnsByParamTypes('all');
          pre_data['data']['general']['descriptive_stats'] = {
            'mean': { 'name': { 'value': 'mean', 'label': 'ortalama' }, 'detail': {} },
            'median': { 'name': { 'value': 'median', 'label': 'ortanca (medyan)' }, 'detail': {} },
            'standart_deviation': { 'name': { 'value': 'standart_deviation', 'label': 'standart sapma' }, 'detail': {} },
            'variance': { 'name': { 'value': 'variance', 'label': 'varyans' }, 'detail': {} },
            'min': { 'name': { 'value': 'min', 'label': 'minimum' }, 'detail': {} },
            'max': { 'name': { 'value': 'max', 'label': 'maksimum' }, 'detail': {} },
            'kurtosis': { 'name': { 'value': 'kurtosis', 'label': 'veri dağılımı (basıklık, kurtosis)' }, 'detail': {} },
            'skewness': { 'name': { 'value': 'skewness', 'label': 'veri dağılımı (eğim, skewness)' }, 'detail': {} },
            'frequency': { 'name': { 'value': 'frequency', 'label': 'sıklık' }, 'detail': {} },
            'kolmogorov_smirnov': { 'name': { 'value': 'kolmogorov_smirnov', 'label': 'veri dağılımı (kolmogrov smirnov)' }, 'detail': {} },
            'shapiro': { 'name': { 'value': 'shapiro', 'label': 'veri dağılımı (shapiro)' }, 'detail': {} },
            'outliers_zindex': { 'name': { 'value': 'outliers_zindex', 'label': 'aykırı değer saptama (outliers) z-index' }, 'detail': {} }
          };
        } else if (easy_stats_type === 'all_columns_test_group_1') {
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'all_columns', 'label': 'tüm kolonlar' } };
          pre_data['data']['general']['descriptive_stats'] = {
            'mean': { 'name': { 'value': 'mean', 'label': 'ortalama' }, 'detail': {} },
            'median': { 'name': { 'value': 'median', 'label': 'ortanca (medyan)' }, 'detail': {} },
            'standart_deviation': { 'name': { 'value': 'standart_deviation', 'label': 'standart sapma' }, 'detail': {} },
            'min': { 'name': { 'value': 'min', 'label': 'minimum' }, 'detail': {} },
            'max': { 'name': { 'value': 'max', 'label': 'maksimum' }, 'detail': {} },
            'kurtosis': { 'name': { 'value': 'kurtosis', 'label': 'veri dağılımı (basıklık, kurtosis)' }, 'detail': {} },
            'skewness': { 'name': { 'value': 'skewness', 'label': 'veri dağılımı (eğim, skewness)' }, 'detail': {} },
            'frequency': { 'name': { 'value': 'frequency', 'label': 'sıklık' }, 'detail': {} },
            'shapiro_wilk': { 'name': { 'value': 'shapiro_wilk', 'label': 'shapiro wilk' }, 'detail': {} }
          };
        }
      }
      return pre_data;
    },
    f_wanalyzerProjectCellCalculate: function () {
      let query = 'project_id=' + this.d_projectId;
      if (this.d_clientId) {
        query += '&client_id=' + this.d_clientId
      }
      if (this.d_wdmrId) {
        query += '&wdmr_id=' + this.d_wdmrId
      }
      let data = {
        'column_list': [],
        'sql_name': this.d_sqlName
      };
      let not_filled_det = false;
      for (let i in this.d_project.column_list) {
        if (this.d_project.column_list[i].wai_input === 1) {
          if (this.d_project.column_list[i].val) {
            let new_input_data = {
              'id': this.d_project.column_list[i].id,
              'label': this.d_project.column_list[i].name,
              'val': this.d_project.column_list[i].val ? this.d_project.column_list[i].val : ''
            }
            data.column_list.push(new_input_data);
          } else {
            if (this.d_clientId || this.d_wdmrId) {
              //
            } else {
              not_filled_det = true;
              break            
            }
          }
        }
      }
      if (not_filled_det) {
        alert(this.StoreLangTranslation.data['please_fill_all_inputs'][this.StoreLang]);
      } else {
        for (let cl_index in this.d_project.column_list) {
          if (this.d_project.column_list[cl_index].wai_output === 1) {
            this.d_project.column_list[cl_index].obj = '';
          }
        }
        this.d_analyzeContinuing = true;
        ServiceFunctions.wanalyzer_project_cell_calculate(query, data)
          .then(resp => {
            this.d_analyzeContinuing = false;
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              for (let col_index in resp.data.column_list) {
                if (resp.data.column_list[col_index].wai_output === 1) {
                  for (let cl_index in this.d_project.column_list) {
                    if (this.d_project.column_list[cl_index].name === resp.data.column_list[col_index].name) {
                      this.d_project.column_list[cl_index].obj = resp.data.column_list[col_index].obj;
                      if (resp.data.column_list[col_index].style !== undefined) {
                        this.d_project.column_list[cl_index].style = resp.data.column_list[col_index].style;
                      }
                      break;
                    }
                  }
                } else if (resp.data.column_list[col_index].wai_input === 1) {
                  for (let cl_index in this.d_project.column_list) {
                    if (this.d_project.column_list[cl_index].name === resp.data.column_list[col_index].name) {
                      if (!this.d_project.column_list[cl_index].val) {
                        let input_val = '';
                        for (let k in resp.data.column_list[col_index].obj) {
                          for (let l_index in resp.data.column_list[col_index].obj[k].list) {
                            input_val = resp.data.column_list[col_index].obj[k].list[l_index].val;
                            break;
                          }
                          if (input_val) {
                            break;
                          }
                        }
                        this.d_project.column_list[cl_index].val = input_val;
                      }
                      break;
                    }
                  }                  
                }
              }
              let time_based_list = [];
              for (let i in this.d_project.column_list) {
                if (this.d_project.column_list[i].wai_output === 1) {
                  if (this.d_project.column_list[i].obj) {
                    for (let obj_key in this.d_project.column_list[i].obj) {
                      let date_string = '';
                      if (this.d_project.column_list[i].obj[obj_key].date) {
                        date_string = this.d_project.column_list[i].obj[obj_key].date;
                      }
                      for (let obj_li_index in this.d_project.column_list[i].obj[obj_key].list) {
                        let obj_data = this.d_project.column_list[i].obj[obj_key].list[obj_li_index];
                        let new_val_item = {
                          'date': date_string,
                          'val': obj_data['val'],
                          'label': this.d_project.column_list[i].name,
                          'background_color': ''
                        };
                        if (this.d_project.column_list[i].style && this.d_project.column_list[i].style.backgroundColor) {
                          new_val_item.background_color = this.d_project.column_list[i].style.backgroundColor;
                        }
                        time_based_list.push(new_val_item);
                      }
                    }
                  }
                }
              }
              let param = 'date';
              let asc_or_desc = 1;
              // let asc_or_desc = -1;
              function compare(a, b) {
                // Use toUpperCase() to ignore character casing
                let param_a = '';
                let param_b = '';
                try {
                  param_a = a[param].toLocaleLowerCase();
                  param_b = b[param].toLocaleLowerCase();
                } catch (err) {
                  param_a = a[param];
                  param_b = b[param];
                }

                let comparison = 0;
                if (param_a > param_b) {
                  comparison = 1;
                } else if (param_a < param_b) {
                  comparison = -1;
                }
                return comparison * asc_or_desc;
              }
              time_based_list.sort(compare);
              // console.log('time_based_list : ', time_based_list);
              if (time_based_list.length > 0) {
                this.d_timeLineLeftToRight = [];
                // first row is year-month-day based.
                // [[{}], [{}], [{"date": "2021-12-6"}], [{"date": "2021-12-7"}], [{"date": "2021-12-16"}], [{"date": "2021-12-27"}], [{"date": "2022-1-4"}] ]
                let first_row = [[{}], [{}]];
                let unique_date_list = [];
                let unique_column_label_list = [];
                for (let t_index in time_based_list) {
                  if (unique_column_label_list.indexOf(time_based_list[t_index].label) === -1) {
                    unique_column_label_list.push(time_based_list[t_index].label);
                  }
                  let year_month_day = '';
                  if (time_based_list[t_index].date) {
                    if (time_based_list[t_index].date.indexOf(' ') !== -1) {
                      year_month_day = time_based_list[t_index].date.split(' ')[0];
                    } else if (time_based_list[t_index].date.indexOf('T') !== -1) {
                      year_month_day = time_based_list[t_index].date.split('T')[0];
                    }
                  }
                  if (year_month_day && unique_date_list.indexOf(year_month_day) === -1) {
                    unique_date_list.push(year_month_day);
                  }
                }
                // console.log('unique_date_list : ', unique_date_list);
                for (let unique_ind in unique_date_list) {
                  first_row.push([{'date': unique_date_list[unique_ind]}]);
                }
                this.d_timeLineLeftToRight.push(first_row);
                for (let unique_ind in unique_column_label_list) {
                  let first_col = {
                    "background_color": "#e5f7f3",
                    "value": unique_column_label_list[unique_ind],
                    "label": unique_column_label_list[unique_ind]
                  };
                  for (let cl_index in this.d_project.column_list) {
                    if (this.d_project.column_list[cl_index].name === unique_column_label_list[unique_ind]) {
                      if (this.d_project.column_list[cl_index].style && this.d_project.column_list[cl_index].style.backgroundColor) {
                        first_col.background_color = this.d_project.column_list[cl_index].style.backgroundColor;
                      }
                      break;
                    }
                  }
                  let new_row = [[first_col], []];
                  for (let i = 0; i < unique_date_list.length; i++) {
                    new_row.push([]);
                  }
                  this.d_timeLineLeftToRight.push(new_row);
                }
              }
              for (let row_index in this.d_timeLineLeftToRight) {
                if (row_index > 0) {
                  let row_label = this.d_timeLineLeftToRight[row_index][0][0]['label'];
                  for (let col_index in this.d_timeLineLeftToRight[row_index]) {
                    if (parseInt(col_index) === 1) {
                      for (let t_index in time_based_list) {
                        if (!time_based_list[t_index].date) {
                          let cell_value = '';
                          let cell_label = '';
                          if (typeof(time_based_list[t_index].val) === 'object') {
                            cell_value = time_based_list[t_index].val.value;
                            cell_label = time_based_list[t_index].val.label;
                          } else {
                            cell_value = time_based_list[t_index].val;
                            cell_label = time_based_list[t_index].val;                              
                          }
                          if (row_label === time_based_list[t_index].label) {
                            let new_cell_item = {
                              'label': cell_label,
                              'value': cell_value,
                              'date': '',
                              'background_color': time_based_list[t_index].background_color
                            };
                            this.d_timeLineLeftToRight[row_index][col_index].push(new_cell_item);
                          }
                        }
                      }
                    } else if (parseInt(col_index) > 1) {
                      let col_date = this.d_timeLineLeftToRight[0][col_index][0]['date'];
                      // row_label ve col_date olan verileri time_based_list içerisinden ekleyeceğiz.
                      for (let t_index in time_based_list) {
                        let year_month_day = '';
                        if (time_based_list[t_index].date) {
                          if (time_based_list[t_index].date.indexOf(' ') !== -1) {
                            year_month_day = time_based_list[t_index].date.split(' ')[0];
                          } else if (time_based_list[t_index].date.indexOf('T') !== -1) {
                            year_month_day = time_based_list[t_index].date.split('T')[0];
                          }
                        }
                        if (year_month_day) {
                          if (col_date === year_month_day) {
                            let cell_value = '';
                            let cell_label = '';
                            if (typeof(time_based_list[t_index].val) === 'object') {
                              cell_value = time_based_list[t_index].val.value;
                              cell_label = time_based_list[t_index].val.label;
                            } else {
                              cell_value = time_based_list[t_index].val;
                              cell_label = time_based_list[t_index].val;                              
                            }
                            if (row_label === time_based_list[t_index].label) {
                              let new_cell_item = {
                                'label': cell_label,
                                'value': cell_value,
                                'date': time_based_list[t_index].date,
                                'background_color': time_based_list[t_index].background_color
                              };
                              this.d_timeLineLeftToRight[row_index][col_index].push(new_cell_item);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              // console.log('this.d_timeLineLeftToRight : ', this.d_timeLineLeftToRight);

              for (let i in this.d_project.column_list) {
                if (this.d_project.column_list[i].obj) {
                  let obj_item_list = [];
                  for (let obj_key in this.d_project.column_list[i].obj) {
                    let obj_date = '';
                    if (this.d_project.column_list[i].obj[obj_key].date) {
                      obj_date = this.d_project.column_list[i].obj[obj_key].date;
                    }
                    let new_obj_item = {
                      'date': obj_date,
                      'key': obj_key
                    };
                    obj_item_list.push(new_obj_item);
                  }
                  let param = 'date';
                  let asc_or_desc = 1;
                  // let asc_or_desc = -1;
                  function compare(a, b) {
                    // Use toUpperCase() to ignore character casing
                    let param_a = '';
                    let param_b = '';
                    try {
                      param_a = a[param].toLocaleLowerCase();
                      param_b = b[param].toLocaleLowerCase();
                    } catch (err) {
                      param_a = a[param];
                      param_b = b[param];
                    }

                    let comparison = 0;
                    if (param_a > param_b) {
                      comparison = 1;
                    } else if (param_a < param_b) {
                      comparison = -1;
                    }
                    return comparison * asc_or_desc;
                  }
                  obj_item_list.sort(compare);
                  let new_obj_order = [];
                  for (let obj_ind in obj_item_list) {
                    new_obj_order.push(obj_item_list[obj_ind].key);
                  }
                  this.d_project.column_list[i].obj_order = new_obj_order;
                }
              }

              this.$forceUpdate();
            } else {
              let txt = 'status_code = ' + resp.data.status_code + '\n';
              txt += 'status_message = ' + resp.data.status_message;
              alert(txt);
            }
          });
      }
    },
  },
  watch: {
    'd_filterData.pagination.current': function () {
      // console.log("d_filterData.pagination", this.d_filterData.pagination);
      this.d_filterData.pagination.start = (this.d_filterData.pagination.current - 1) * this.d_filterData.pagination.perpage;
      this.d_filterData.pagination.end = (this.d_filterData.pagination.perpage * this.d_filterData.pagination.current);
      this.f_wanalyzerProjectStatsResultList();
    },
    'd_tablePagination.current': function () {
      this.d_tablePagination.start = (this.d_tablePagination.current - 1) * this.d_tablePagination.perpage;
      this.d_tablePagination.end = (this.d_tablePagination.perpage * this.d_tablePagination.current) - 1;
      this.f_tableShow();
    },
    'd_clientId': function () {
      // this.f_resetForm();
    }
  }
}

</script>

