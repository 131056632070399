<template>
  <div style="min-height: 750px;" v-if="d_usrWdm">
    <template>
      <b-row>
        <b-col sm="12" lg="2"></b-col>
        <b-col sm="12" lg="8"></b-col>
        <b-col sm="12" lg="2"></b-col>
      </b-row>
      <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
        <b-col sm="12" md="2" lg="2"> </b-col>
        <b-col sm="12" md="4" lg="4">
          <i class="fa fa-user-o"></i>
          {{ StoreLangTranslation.data['general_settings'][StoreLang] }}
        </b-col>
        <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
        <b-col sm="12" md="2" lg="2">
        </b-col>
      </b-row>
      <b-row style="font-size: 16px;">
        <b-col sm="12" lg="2"></b-col>
        <b-col sm="12" lg="8">
          <template v-if="d_frontendLoading">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </template>
          <template>
            <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
              <b-row>
                <b-col sm="12" lg="8">
                  <h6>
                    <i class="fa fa-calculator"></i>
                    {{ StoreLangTranslation.data['personal_settings'][StoreLang] }}
                  </h6>
                </b-col>
                <b-col sm="12" lg="4" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_usrWdm['0']['parameters']['1']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-form-input required type="text" v-model="d_userData.firstname"/>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_usrWdm['0']['parameters']['2']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-form-input required type="text" v-model="d_userData.lastname"/>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_usrWdm['0']['parameters']['5']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <select required class="form-control" v-model="d_userData.gender">
                    <option v-for="(op, op_index) in d_usrWdm['0']['parameters']['5'].options" :value="op.value">
                      {{ op.translation[StoreLang] }}
                    </option>
                  </select>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_usrWdm['0']['parameters']['4']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-form-datepicker 
                    v-model="d_datePickerDate" 
                    :locale="StoreLang" 
                    today-button
                    reset-button
                    close-button/>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_usrWdm['0']['parameters']['13']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <select required class="form-control" v-model="d_userData.active_lang">
                    <option v-for="(op, op_index) in d_usrWdm['0']['parameters']['13'].options" :value="op.value">
                      {{ op.translation[StoreLang] }}
                    </option>
                  </select>
                </b-col>
              </b-row>
            </b-card-body>


            <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
              <b-row>
                <b-col sm="12" lg="8">
                  <h6>
                    <i class="fa fa-calculator"></i>
                    {{ StoreLangTranslation.data['contact_settings'][StoreLang] }}
                  </h6>
                </b-col>
                <b-col sm="12" lg="4" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_usrWdm['0']['parameters']['24']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-textarea rows="5" v-model="d_userData.address"></b-textarea>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_usrWdm['0']['parameters']['25']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-form-input type="number" v-model="d_userData.phone_number"/>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_usrWdm['0']['parameters']['27']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-form-input type="number" v-model="d_userData.phone_area_code"/>
                </b-col>
              </b-row>
            </b-card-body>


            <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
              <b-row>
                <b-col sm="12" lg="8">
                  <h6>
                    <i class="fa fa-calculator"></i>
                    {{ StoreLangTranslation.data['social_media_settings'][StoreLang] }}
                  </h6>
                </b-col>
                <b-col sm="12" lg="4" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_usrWdm['0']['parameters']['14']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-form-input type="text" v-model="d_userData.facebook"/>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_usrWdm['0']['parameters']['15']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-form-input type="text" v-model="d_userData.instagram"/>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_usrWdm['0']['parameters']['16']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-form-input type="text" v-model="d_userData.linkedin"/>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_usrWdm['0']['parameters']['17']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-form-input type="text" v-model="d_userData.twitter"/>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_usrWdm['0']['parameters']['18']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-form-input type="text" v-model="d_userData.website"/>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_usrWdm['0']['parameters']['19']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-form-input type="text" v-model="d_userData.youtube"/>
                </b-col>
              </b-row>
            </b-card-body>
            <b-button @click="f_userRecord" variant="outline-primary" style="float: right;">
              <i class="fa fa-save"></i>
              {{ StoreLangTranslation.data['save'][StoreLang] }}
            </b-button>

          </template>
        </b-col>
        <b-col sm="12" lg="2"></b-col>
      </b-row>
    </template>
  </div>
</template>

<script>

import { API_BASE_URL } from '@/config';
import { ServiceFunctions } from '@/wam/modules/service';
import { ClsWdm } from '@/wam/modules/wdm';
import store from '@/store';
import {
  default as Page404
} from '@/wam/components/widgets/Page404';
import {
  default as Login
} from '@/wam/views/login';
import { mapGetters } from 'vuex';
export default {
  name: 'user_settings',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreLang: 'StoreLang',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreLangList: 'StoreLangList',
  }),
    components: {
    Page404,
    Login,
  },
  props: {},
  data () {
    return {
      // d_editParameter: '',
      // d_systemDocumentList: [],
      // d_imageUploadModal: false,
      // d_fullScreen: false,
      // d_tmpFile: null,
      d_showPassword: {
        'mail_smtp_password': false,
        'worganization_api_username': false,
        'worganization_api_password': false,
        'worganization_token': false,
        'register_mail_approve_mail_from_password': false,
        'mail_api_token_username': false,
        'mail_api_token_password': false,
        'waccount_password': false,
        'support_mail_from_password': false,
      },
      d_usrWdm: '',
      d_userData: {
        'status': '',
        'firstname': '',
        'lastname': '',
        'username': '',
        'date_of_birth': '',
        'gender': '',
        'eula_approve_status': '',
        'eula_approve_date': '',
        'eula_document_code': '',
        'mail_approve_status': '',
        'mail_approve_token': '',
        'mail': '',
        'cover_photo': '',
        'active_lang': '',
        'facebook': '',
        'instagram': '',
        'linkedin': '',
        'twitter': '',
        'website': '',
        'youtube': '',
        'hashed_password': '',
        'about_me': [],
        'note': '',
        'phone_number_approve_status': '',
        'address': '',
        'phone_number': '',
        'phone_number_approve_token': '',
        'phone_area_code': '',
        'create_date': '',
        'mail_password_reset_token': '',
        'password_reset_date': '',
        'password_reset_status': '',
      },
      d_datePickerDate: '',
      // d_user: '',
      d_pageShow: false,
      d_page404Show: false,
      d_frontendLoading: false,

    };
  },
  created: function () {
    ClsWdm.getLayerWdm('usr').then(resp_wdm_data => { this.d_usrWdm = resp_wdm_data; });
    // this.d_user = JSON.parse(localStorage.getItem('user'));
    
    // this.f_langEligibleParameterProcess();
    // this.d_frontendData = JSON.parse(JSON.stringify(this.StoreFrontendSettings.data));
    this.f_getUserSettings();
    // console.log('this.d_frontendData.menu_type', this.d_frontendData.menu_type);
    // console.log(JSON.stringify(this.d_frontendData));
    
    
  },
  beforeMount () {},
  mounted: function () {},
  methods: {
    // f_langEligibleParameterProcess: function () {
    //   let lang_eligible_variables = ['firstname', 'lastname', 'date_of_birth', 'cover_photo', 'facebook', 'instagram', 'linkedin', 'twitter', 'website', 'youtube', 'address', 'phone_number', 'phone_area_code'];
    //   for (let k in lang_eligible_variables) {
    //     for (let lang_index in this.StoreLangList.data) {
    //       if (!this.d_userData[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label]) {
    //         this.d_userData[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label] = '';
    //       }
    //     }
    //   }
    // },
    f_userRecord: function () {
      if(this.d_userData.firstname !== "" && this.d_userData.lastname !== "" && this.d_userData.gender !== "" && this.d_userData.active_lang !== "" && this.d_userData.date_of_birth !== ""){
        let query = '';
        let data = {
          user_data: {
            'firstname': this.d_userData.firstname,
            'lastname': this.d_userData.lastname,
            'date_of_birth': this.d_userData.date_of_birth,
            'gender': this.d_userData.gender,
            // 'cover_photo': this.d_userData.cover_photo,
            'active_lang': this.d_userData.active_lang,
            'facebook': this.d_userData.facebook,
            'instagram': this.d_userData.instagram,
            'linkedin': this.d_userData.linkedin,
            'twitter': this.d_userData.twitter,
            'website': this.d_userData.website,
            'youtube': this.d_userData.youtube,
            'address': this.d_userData.address,
            'phone_number': this.d_userData.phone_number,
            'phone_area_code': this.d_userData.phone_area_code
          }
        };
        ServiceFunctions.user_record(query, data)
          .then(resp => {
            // console.log("resp.data")
            // console.log(resp.data)
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              alert(this.StoreLangTranslation.data['update_success_message'][this.StoreLang]);
            } else {
              alert(resp.data.status_code + ' = ' + resp.data.status_message);
            }
          });
        } else {
          alert(this.StoreLangTranslation.data['empty_input_message'][this.StoreLang] + 
            '\n-' + this.d_usrWdm['0']['parameters']['1']['name']['translation'][this.StoreLang] +
            '\n-' + this.d_usrWdm['0']['parameters']['2']['name']['translation'][this.StoreLang] +
            '\n-' + this.d_usrWdm['0']['parameters']['4']['name']['translation'][this.StoreLang] +
            '\n-' + this.d_usrWdm['0']['parameters']['5']['name']['translation'][this.StoreLang] +
            '\n-' + this.d_usrWdm['0']['parameters']['13']['name']['translation'][this.StoreLang]
            );
        }
      
    },
    f_getUserSettings: function () {
      let query = '';
      let data = {};
      this.d_frontendLoading = true;
      ServiceFunctions.user_get(query, data)
        .then(resp => {
          this.d_frontendLoading = false;
          // console.log("resp.data")
          // console.log(resp.data)
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_userData = resp.data.user;
            this.f_setDatepickerDateOfBirth();
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_setDatepickerDateOfBirth: function() {
      if (this.d_userData.date_of_birth) {
        const [day, month, year] = this.d_userData.date_of_birth.split(".");
        // this.d_userData.date_of_birth = `${day}.${month}.${year}`;
        this.d_datePickerDate = year + '-' + month + '-' + day;
      } else {
        this.d_datePickerDate = "";
      }
    }
  },
  watch:{
    'd_datePickerDate': function() {
      if (this.d_datePickerDate) {
        const [year, month, day] = this.d_datePickerDate.split("-");
        // this.d_userData.date_of_birth = `${day}.${month}.${year}`;
        this.d_userData.date_of_birth = day + '.' + month + '.' + year;
      } else {
        this.d_userData.date_of_birth = "";
      }
    },
    'd_userData.date_of_birth': function() {
      console.log('this.d_userData.date_of_birth');
      console.log(this.d_userData.date_of_birth);
    }
  }

}

</script>

